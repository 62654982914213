.angel-page-card {
  width: 680px;
  margin: auto;
}

.angel-page-fulfilled-slider {
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin-left: -5px;
  width: auto;
  scroll-behavior: smooth;
}

@media (max-width: 862px) {
  .angel-page-card {
    width: 98%;
    margin: auto;
  }
}
