.profile-fulfilled-card {
  width: fit-content;
  height: 54px;
  padding: 10px;
  margin: auto;
  gap: 6px;
  border-radius: 8px;
  background-color: white;
}

.profile-fulfilled-container-page {
  width: 1040px;
  display: flex;
  justify-content: center;
}

.profile-fulfilled-dream-card {
  background: white;
  border-radius: 8px;
  margin-right: 20px;
  padding: 10px;
  height: 354px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  gap: 10px;
}

.profile-fulfilled-dream-card-header {
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  justify-content: flex-start;
}

.profile-fulfilled-button-page {
  width: auto;
  height: 34px;
  padding: 8px 20px 8px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.profile-fulfilled-active-page-button {
  background: #f5f5f5;
  font-weight: 700;
}

.dreamer-fulfill-avatar {
  border-radius: 50%;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.dreamer-fulfill-name-and-date {
  font-weight: 800;
  color: #444444;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 36px;
}

.profile-fulfilled-button-text {
  font-family: Space Grotesk;
  font-size: 14px;
  line-height: 17.86px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
}

.missing-profile-fulfilled-data {
  margin-right: auto;
  margin-left: auto;
  margin-top: -20px;
  width: 320px;
}

.charity-profile-fulfilled-card {
  height: 402px;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.charity-profile-fulfilled-header {
  height: 36px;
}

.charity-profile-fulfilled-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.charity-profile-fulfilled-name {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.86px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
}

.charity-profile-fulfilled-time {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
}

.charity-profile-fulfilled-received {
  display: flex;
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: left;
  color: #404040;
  gap: 6px;
}

.dream-fulfill-card-progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.profile-received-card-header {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.profile-received-card {
  background: white;
  border-radius: 8px;
  padding: 0 0 10px 0;
  height: 322px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.profile-received-main-box {
  width: 100%;
  height: 183px;
  padding: 20px 10px 0px 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: space-between;
}

.profile-received-avatar {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 3px solid white;
  cursor: pointer;
}

.profile-received-img-mg {
  margin-top: -59px;
}

.profile-received-username-box {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  color: #404040;
}

.profile-received-buttons-text {
  font-family: Space Grotesk;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  color: black;
}

.angel-received-avatar {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 2px solid white;
  cursor: pointer;
}

.angel-received-img-border {
  padding: 2px;
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
}

.angel-received-card-header {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.angel-received-img-mg {
  margin-top: -71px;
}

.received-angel-fulfilled-box {
  height: 17px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: left;
  color: black;
}

.profile-angel-badge-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.angel-received-main-box {
  width: 100%;
  margin-top: 10px;
  padding: 10px 10px 0px 10px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.profile-fulfilled-dream-card .carousel {
  width: 100%;
  height: 140px;
}

.wing-fulfil-image-style {
  width: 100%;
  object-fit: cover;
  height: 140px;
  border-radius: 10px;
}

.name-overflow-cards {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.text-underline:hover {
  text-decoration: underline;
}

.dreamer-fulfilled-image {
  width: 100%;
  border-radius: 8px;
  height: 140px;
  margin-bottom: 10px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .profile-fulfilled-dream-card {
    height: 287px;
  }
  .dreamer-fulfilled-image {
    height: 100px;
  }
  .profile-fulfilled-dream-card .carousel {
    height: 100px;
  }
  .dream-fulfill-card-progress-container {
    margin: 0 !important;
  }
}
