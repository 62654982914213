.profile-navbar {
  width: 860px;
}

.navbar-item-box {
  height: 42px;
}

.navbar-user-profile.nav-pills.profile-feed-items li a.active {
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.2) 1.02%,
    rgba(235, 191, 255, 0.2) 44.33%,
    rgba(246, 236, 133, 0.2) 76.07%
  ) !important;
  opacity: 1 !important;
  border-radius: 8px 8px 0px 0px !important;
}

.navbar-user-profile span {
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.02em;
}

.user-page-bg-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1040px;
  height: 330px;
  top: 0 !important;
  overflow: hidden;
}

.profile-img-border {
  border-radius: 50%;
  border: 4px solid white;
  cursor: pointer;
}

.profile-img-border img {
  width: 132px;
  height: 132px;
}

.profile-angel-img-border {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
}

.profile-angel-img-border {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  padding: 3px;
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
}

.border-radius-img {
  border-radius: 50%;
  box-shadow: 0px 4px 8px 0px #0000000f;
}

.profile-user-name {
  color: #252525;
  font-size: 28px;
  letter-spacing: -0.04em;
}

.profile-angel-badge {
  width: 87px;
  height: 26px;
  padding: 4px;
  gap: 10px;
  border-radius: 4px;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.4) 1.02%,
    rgba(235, 191, 255, 0.4) 44.33%,
    rgba(246, 236, 133, 0.4) 76.07%
  );
}

.nav-back-color {
  background-color: white;
}

.profile-angel-badge p {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #000000;
}

.angel-fulfilled-box {
  gap: 4px;
  display: flex;
}

.angel-fulfilled-box span {
  color: #6c6a6a;
  letter-spacing: -0.04em;
}

.angel-fulfilled-box span:nth-of-type(2) {
  font-weight: 600;
}

.profile-user-center-info {
  justify-content: space-between;
  display: flex;
  margin-top: -50px;
  width: 680px;
  height: 24px;
}

.edit-profile-button {
  width: 115px;
  height: 32px;
  padding: 6px 10px;
  display: flex;
  gap: 6px;
  border-radius: 20px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.drop-down-bg {
  right: 20px;
  top: 285px;
  position: absolute;
}

.edit-profile-button span {
  letter-spacing: -0.04em;
  color: #6c6a6a;
  line-height: 17.86px;
}
.dropdown-toggle::after {
  display: none !important;
}

.edit-profile-button-container-dream {
  margin-top: 7 !important;
}

.edit-profile-button-container-angel {
  margin-top: 9 !important;
}

.set-drop-down {
  position: absolute;
  right: 16px;
  top: 280px;
  z-index: 999;
}

@media (max-width: 1200px) {
  .user-page-bg-img {
    width: 90vw;
    height: 280px;
  }
  .drop-down-bg {
    top: 240px;
  }
  .set-drop-down {
    top: 235px;
    right: 10px;
  }
}

@media (max-width: 880px) {
  .profile-navbar {
    width: 96vw;
  }
}

@media (max-width: 770px) {
  .user-page-bg-img {
    width: 95vw;
    height: 240px;
  }
  .drop-down-bg {
    top: 200px;
  }
  .profile-user-center-info {
    margin-top: 0;
    justify-content: space-around;
    width: auto;
    gap: 4px;
  }
  .profile-user-name {
    font-size: 24px;
  }
  .edit-profile-button-container-dream {
    margin-top: 0 !important;
  }
  .set-drop-down {
    top: 195px;
    right: 10px;
  }
}

@media (max-width: 600px) {
  .user-page-bg-img {
    width: 95vw;
    height: 200px;
  }
  .profile-img-border img {
    width: 100px;
    height: 100px;
  }
  .drop-down-bg {
    top: 160px;
  }
  .bg-img-top {
    margin-top: 10px !important;
  }
  .set-drop-down {
    top: 10px;
    right: 10px;
  }
}

.bg-img-top {
  margin-top: 20px;
}
