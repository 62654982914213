body {
  scrollbar-width: none !important;
}
.main-row-newsfeed {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1440px) {
  .main-row-newsfeed {
    justify-content: none;
  }
}
.margin-top-right {
  margin-top: 3px;
}

.black-hover-dream {
  border: 1px solid #efefef;
}
.margin-20-responsive {
  margin: 20px;
}

.black-hover-dream:hover {
  border: 1px solid black;
  border-radius: 8px;
}

.black-hover:hover {
  border: 1px solid rgb(188, 188, 188);
  border-radius: 8px;
}
.share-container {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.copied-btn {
  height: 34px;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  padding: 12px 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #404040;
  white-space: nowrap;
}
.social-icon-container {
  flex-wrap: wrap;
}
.share-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.share-modal-title {
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
}
.modal-bg-opacity {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.modal-bg-opacity-color {
  width: 1000vh;
  height: 1000vh;
  background: black;
  opacity: 0.6;
  position: absolute;
  pointer-events: none;
}
.none-arrow-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.cursor-pointer {
  cursor: pointer;
}

.font-cards-text {
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(51, 51, 51);
}

.my-span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-name {
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333333;
  line-height: normal;
}
.user-name:hover {
  text-decoration: underline;
}
.time-ago {
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
}

.my-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
}

.font-hover {
  font-style: normal;
}

.font-hover:hover {
  font-weight: bold;
  color: black;
}
.avatar {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.notification-arrow {
  position: absolute;
  top: -11px;
  right: 8px;
}
.notification-item-container-reminder {
  cursor: pointer;
  gap: 8px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px !important;
  background: #fdfadb;
}
.notification-item-container-reminder:hover {
  background: #ebebeb;
}
.notification-item-container {
  cursor: pointer;
  gap: 8px;
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 8px !important;
  background: white;
}
.notification-item-container:hover {
  background: #ebebeb;
}
.avatar-menu {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.nav-avatar {
  height: 48px;
  width: 48px;
  object-fit: cover;
}

/*gradient buttons */
.custom-gradient,
.custom-gradient-popular-dream {
  background: linear-gradient(white, white),
    linear-gradient(
      160deg,
      rgb(132, 250, 213) 20%,
      rgb(235, 191, 255) 37%,
      rgb(246, 236, 133) 53%
    );
  border-radius: 8px;
  border: double 1px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: 500;
  font-size: 14px;
}
.popular-dream-btn-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.custom-gradient:hover {
  background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
  border-radius: 8px;
  font-weight: bold;
}
.custom-gradient-popular-dream:hover > .popular-dream-btn-text {
  font-weight: 700;
  line-height: 18px;
}
.custom-gradient-popular-dream:hover {
  background: linear-gradient(
    160deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
}
.button-disable {
  background: linear-gradient(white, white),
    linear-gradient(
      160deg,
      rgb(132, 250, 213) 20%,
      rgb(235, 191, 255) 37%,
      rgb(246, 236, 133) 53%
    );
  border-radius: 8px;
  border: double 1px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

/*dashboard buttons*/

.create-dream-button {
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;
}

.create-dream-button:hover {
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  border: 1px solid #4f4f4f;
  font-weight: 700;
}

.cash-out-button {
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  color: black;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.cash-out-button:hover {
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  border: 0.8px solid #4f4f4f;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
}

.dashboard-card-buttons {
  height: 36px;
  width: 48%;
}

.dashboard-card-buttons-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: black !important;
}

.dashboard-card-disable {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: black;
}

.dashboard-card-buttons:hover > .dashboard-card-buttons-text {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #262626 !important;
}

.right-side-dashboard-buttons {
  width: 55px;
  height: 36px;
}

.right-side-dashboard-buttons:hover > .dashboard-card-buttons-text {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #262626;
}
::-webkit-scrollbar-track {
  background: transparent;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar {
  display: none;
}

/*NavigatorDropDown*/

.nav-drop-down {
  width: 168px;
  height: 136px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
}

.border-gradient {
  border: double 1px transparent !important;
  background-image: linear-gradient(white, white),
    linear-gradient(150.93deg, #84fad5 1.02%, #ebbfff 44.33%, #f6ec85 76.07%) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
}

.text-gradient {
  font-size: 22px;
  background: linear-gradient(
    to right,
    rgba(132, 250, 213, 0.8) 20%,
    rgba(172, 98, 241, 0.4) 50%,
    rgba(248, 237, 132, 0.8)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard-list-image-style {
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.dream-makers-text {
  font-weight: 700;
  font-size: 16px;
  color: #4f4f4f;
}

.notification-comment-username {
  padding-right: 5px;
}
.mobile-padding {
  padding: 1.25rem !important;
}
.gray-line {
  color: #f1f1f1;
}
.height-auto {
  height: auto;
}
.profile-profile-bg {
  height: 325px;
}

.public-main-image {
  position: relative;
  margin-top: -4.3rem;
}
.display-none-mobile {
  display: none;
}
.margin-dream-page {
  margin-bottom: 1.875rem;
}
.margin-posts {
  margin-top: 20px;
  max-width: 680px;
}
.mobile-card {
  padding: 20px;
}
.mobile-15-card {
  padding: 20px;
}
.complete-container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  margin: 0;
}
.gap-20-complete {
  gap: 15px;
}
.notification-dropdown {
  max-height: 500px;
  overflow: scroll;
}
.test-overflow {
  overflow: hidden !important;
}
@media screen and (max-width: 500px) {
  .gap-20-complete {
    gap: 20px;
  }
  .notification-dropdown {
    max-height: 435px;
  }
  .margin-posts {
    margin-top: 0;
  }
  .complete-container {
    margin-bottom: 30px;
    padding: 20px 10px;
  }
  .margin-20-responsive {
    margin: 10px;
  }
  .nav-avatar {
    height: 40px;
    width: 40px;
  }
  .margin-dream-page {
    margin-bottom: 0;
  }
  .padding-unset {
    padding: 0 !important;
  }
  .display-none-mobile {
    display: unset;
  }
  .display-desktop-none {
    display: none;
  }
  .mobile-padding {
    padding: 1.25rem 0 1.25rem 0 !important;
  }
  .profile-profile-bg {
    height: 200px;
  }
}

.profile-list-image-style {
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.posts-buttons-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.post-image {
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 8px;
}
.post-sponsored-image {
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 9px;
}

.image-slider {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.bg-gradient {
  background: linear-gradient(
    -5deg,
    #f8ed84 0%,
    #f5e0ff 70%,
    #84fad5 100%
  ) !important;
}

.custom-btn {
  margin-bottom: 10px;
  width: 46%;
  height: 100%;
  font-size: 14px;
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  opacity: 6;
  border-radius: 8px;
  color: #000000;
  display: inline-block;
  text-decoration: none;
  border: none;
}

.custom-btn:hover {
  border: 1px solid black;
}

.profile-photo-drag-save {
  width: 110px;
  height: 34px;
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
}
.carousel-indicators {
  margin-bottom: 0;
}

.profile-photo-drag-save:hover {
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  border: 0.8px solid #4f4f4f;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
}

.profile-photo-drag-cancel {
  background: linear-gradient(white, white),
    linear-gradient(
      160deg,
      rgb(132, 250, 213) 20%,
      rgb(235, 191, 255) 37%,
      rgb(246, 236, 133) 53%
    );
  border-radius: 8px;
  border: double 1px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 34px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
  margin-left: 10px;
}

.profile-photo-drag-cancel:hover {
  background: #ffffff;
  border-radius: 8px;
  width: 110px;
  height: 34px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
  cursor: pointer;
  margin-left: 10px;
}

.custom-btn-span {
  text-align: center;
  color: #000000;
  background: #fff;
  display: block;
  padding: 2px 3px;
  border-radius: 6px;
}

.custom-btn-span:hover {
  font-weight: bold;
  background: none;
}

.defaultImg {
  width: 47%;
  height: 112px;
  background: #f5f5f5;
  color: #bdbdbd;
  cursor: pointer;
}

.defaultImg:hover {
  border: 1px solid #e5e5e5;
  color: #828282;
}

.dropDownItem {
  color: #4f4f4f;
  cursor: pointer;
}

.dropDownItem:hover {
  color: black;
}

.top-notations {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 15px 0;
}

.content-page-center-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.card-width {
  width: 100%;
  height: 50%;
}

/*DashBoard Left Side*/
.wing-avatar {
  border-radius: 8px;
  box-sizing: border-box;
  width: 58px;
  height: 58px;
}

.wing-title {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
}

.wing-donation-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 34px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-image: linear-gradient(white, white),
    linear-gradient(
      to right,
      rgba(132, 250, 213, 0.8) 20%,
      rgba(172, 98, 241, 0.4) 50%,
      rgba(248, 237, 132, 0.8) 80%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.wing-donation-button:hover {
  background: linear-gradient(
    320deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  border-radius: 8px;
}

.wing-donation-button:hover > .wing-donate-span {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #262626;
}

.wing-donate-span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

/*DashBoard Right Side*/

/*Create Button Css*/

.dashboard-create-button {
  font-size: 14px;
  background: linear-gradient(to right, #f8ed84 20%, #f5e0ff 50%, #84fad5 80%);
  border-radius: 7px;
  color: #000000;
  display: inline-block;
  padding: 1px;
  text-decoration: none;
  border: 1px solid transparent;
  border-image: linear-gradient(
    to right,
    #84fad5 20%,
    #ebbfff 50%,
    #f6ec85 80%
  );
  margin: 10px;
  font-weight: 400;
  line-height: 16px;
}

.users-card-bg-image {
  width: 100%;
  height: 124px;
  border-radius: 10px 10px 0 0;
}
.bg-card {
  width: 100%;
  height: 130px;
  border-radius: 10px 10px 0 0;
}
.pro-tip-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(
    325deg,
    rgba(248, 237, 132, 0.3) 0%,
    rgba(245, 224, 255, 0.3) 70%,
    rgba(132, 250, 213, 0.3) 100%
  );
  margin-bottom: 20px;
}

.two-rows-text-area-left-bar {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gradient-button-left-side {
  width: 100%;
  font-size: 14px;
  background: linear-gradient(
    140deg,
    rgba(132, 250, 213, 0.8) 20%,
    rgba(172, 98, 241, 0.4) 50%,
    rgba(248, 237, 132, 0.8) 80%
  );
  border-radius: 7px;
  color: #000000;
  display: inline-block;
  padding: 1px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.gradient-button-left-side:hover {
  background: linear-gradient(
    140deg,
    rgba(248, 237, 132, 0.8) 20%,
    rgba(172, 98, 241, 0.4) 50%,
    rgba(132, 250, 213, 0.8) 80%
  );
}

/*Commnet Modal CSS*/

.commnet-reply {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #828282;
}

.all-reply {
  display: flex;
  overflow: auto;
  width: 200px;
  border: 1px solid black;
  border-radius: 8px;
}

.comment-reply {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: black;
}

.reply-hover-bottom-line:hover {
  text-decoration: underline;
}

.see-all-comments {
  cursor: pointer;
}

.reply-hover-second {
  display: flex;
  justify-content: center;
  height: 17px;
  width: 36px;
}

.reply-hover-second:hover {
  text-decoration: underline;
}
.underline-name:hover {
  cursor: pointer;
  text-decoration: underline;
}

.see-all-comments:hover {
  cursor: pointer;
  border-bottom: solid 2px #828282;
}

.comment-create-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  padding-top: 1px;
}

.comment-css {
  background: #f1f1f1;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
}

.reply-css {
  background: #f1f1f1;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
}

.text-underline:hover {
  text-decoration: underline;
}
.comment-message {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #6c6a6a;
  margin-top: 4px;
}

.comment-reply-create-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  height: 22px;
  padding-right: 7px;
  display: flex;
}

.comment-reply-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background: #f5f5f5;
  height: 40px;
  border-radius: 8px;
}

.arrow-start {
  padding-bottom: 20px;
}

.arrow-end {
  padding-left: 15px;
}

.padding-for-golden {
  padding: 4px 4px 4px 4px;
}

.reply-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
  cursor: default;
  display: inline-block;
  width: auto;
  white-space: pre;
  margin-left: 8px;
}

.reply-input {
  width: 85%;
  height: 100%;
  background: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: black;
}

.avatar-reply {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  margin-right: 10px;
}

.comment-form {
  display: flex;
  background: #fbfbfb;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  flex-wrap: wrap;
  cursor: text;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  min-height: 88px;
}

.comment-horizontal {
  border-top: 1px solid #f1f1f1;
}
.responsive-modal-input {
  position: sticky;
  z-index: 11;
  bottom: 0;
  width: 100%;
  background: white;
  padding-bottom: 20px;
  padding-top: 20px;
}
.responsive-container {
  height: 85vh;
  box-shadow: -10px 0 10px -1px rgba(180, 183, 196, 0.2);
  padding: 20px;
}

.comment-user-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: black;
  width: max-content;
}
.comment-user-name-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}

.picture-container {
  margin: 0 auto;
  overflow: hidden;
  height: 300px;
  position: relative;
  width: 80%;
}

.picture-container img {
  min-height: 100%;
  min-width: 100%;
}

.controls,
.picture-container img {
  position: absolute;
}

.controls a {
  text-decoration: none;
  color: white;
  padding: 15px;
  display: inline-block;
}

.leava-a-comment {
  font-style: normal;
  font-weight: 420;
  font-size: 16px;
  line-height: 16px;
  color: #bdbdbd;
}

.comment-text-list {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #6c6a6a;
  margin-top: 4px;
}

.comment-date {
  font-style: normal;
  font-weight: 420;
  font-size: 17px;
  line-height: 15px;
  color: #828282;
}

.background-img-newsfeed-box {
  width: 100%;
  height: 130px;
  border-radius: 10px 10px 0 0;
}

.comment-header-title {
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  letter-spacing: -0.56px;
  color: #101422;
}

.comment-text-container {
  padding: 8px 12px 8px 12px;
  cursor: text;
  overflow-x: hidden;
  flex-basis: auto;
  text-overflow: ellipsis;
  flex-shrink: 1;
  white-space: nowrap;
  flex-grow: 1;
  display: block;
}

.comment-text {
  user-select: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: auto;
  max-height: 200px;
  justify-self: flex-start;
  padding-right: 3px;
}
.comments-input {
  background-color: #f5f5f5 !important;
  height: 40px;
  padding-right: 60px;
}
.comments-input:focus {
  border: 1px solid #a8a6a6 !important;
  color: black !important;
}

.comment-text:empty:not(:focus):before {
  content: attr(data-placeholder);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}

.comment-text:empty:focus:before {
  content: "";
}
.chat-icons {
  display: flex;
  align-self: flex-start;
  gap: 5px;
}
.comment-icons {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  right: 5px;
  bottom: 0px;
}
.chat-emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: #828282;
  border-radius: 8px;
  cursor: pointer;
}
.emoji-icon-container:focus {
  background-color: red;
}
.emoji-icon-container:hover {
  background-color: #ebebeb;
}
.emoji-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.emoji-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: #828282;
  border-radius: 8px;
  cursor: pointer;
}

.emoji-container:hover {
  color: black;
  background-color: #d7d7d7;
}
.emoji-picker-container-bottom {
  position: absolute;
  top: 40px;
  right: -5px;
}
.emoji-picker-container-top-chat {
  position: absolute;
  bottom: 77px;
  right: 10px;
}
.emoji-picker-container-top {
  position: absolute;
  bottom: 45px;
  right: -5px;
}

.emoji-picker-container .emoji-img {
  height: 27px !important;
  width: 27px !important;
  margin: 5px !important;
}

.emoji-picker-container .native {
  height: 22px !important;
  width: 22px !important;
  margin: 5px !important;
}

/*Golden Angel Border*/

.container-gold {
  width: 100%;
  border-bottom: 3px solid #ffc120;
  position: absolute;
  margin-top: 2px;
  background: none;
}

.gold-container-half-outer {
  transform: translateX(-50%);
  position: absolute;
  width: 200px;
  height: 100px;
  overflow: hidden;
  left: 50%;
}

.gold-half-circle {
  width: 72px;
  height: 32px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border-bottom: 3px solid #ffc120;
  border-left: 3px solid #ffc120;
  border-right: 3px solid #ffc120;
  border-bottom-left-radius: 125px;
  border-bottom-right-radius: 125px;
  background: transparent;
  object-fit: initial;
  z-index: 1;
}

.container-gold-main {
  width: 100%;
  overflow: hidden;
  margin-top: 4px;
  border-width: 4px;
  border-bottom: 4px double transparent;
  border-image: linear-gradient(
    90deg,
    rgba(255, 193, 32, 0.1) 0.16%,
    #ffc120 48.95%,
    rgba(255, 193, 32, 0.06) 100%
  );
  border-image-slice: 2;
  background: none;
}

.gold-container-outer {
  transform: translateX(-50%);
  position: absolute;
  width: 200px;
  height: 100px;
  overflow: hidden;
  left: 50%;
}

.gold-half-circle-main {
  width: 144px;
  height: 77px;
  background: none;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-top: 1px;
  transform: translateX(-50%);
  border-bottom: 6px solid #ffc120;
  border-left: 6px solid #ffc120;
  border-right: 6px solid #ffc120;
  border-bottom-left-radius: 120px;
  border-bottom-right-radius: 120px;
}

.right-side-overflow {
  position: fixed;
  right: 0;
  overflow: hidden;
}

.left-side-overflow {
  position: sticky;
  top: 73px;
  left: 0;
}

.left-side-overflow-post {
  position: sticky;
  left: 0;
  top: 63px;
  height: 94vh;
  overflow: scroll;
}
.padding-post {
  padding: 0 20px;
}
.padding-card {
  padding: 0 10px;
}
.padding-left-card {
  padding: 20px;
  max-width: 320px;
  scrollbar-width: none;
}
.post-gap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.left-side-scroll {
  max-height: 402px;
  overflow: scroll;
}

.left-side-scroll:focus {
  overflow: scroll;
}

.remove-scroll::-webkit-scrollbar {
  display: none;
}

.add-scroll {
  display: block;
}

.left-side-scroll {
  -ms-overflow-style: none;
  /*padding-bottom: 35px;*/
}

.right-side-overflow {
  position: fixed;
  right: 0;
  overflow: hidden;
}

.scroll-hidden-right {
  -ms-overflow-style: none;
  padding-bottom: 35px;
}

.scroll-hidden-right::-webkit-scrollbar {
  display: none;
}

.modal-comment-image {
  width: 100%;
  height: 85vh;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.price-modal-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.price-modal-month {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: black;
}

/*Price Modal Css*/

.bg-img-price {
  background-image: url("../assets/images/DashBoardImages/priceBackground.jpg");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: initial !important;
}

.price-modal-img {
  background-image: url("../assets/images/DashBoardImages/priceBackground.jpg");
  /*max-height: 600px;*/
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 8px;
}
.padding-60-responsive {
  padding: 60px;
}

.price-modal-card {
  font-style: normal;
  width: 33%;
  height: 319px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  background-image: linear-gradient(white, white),
    linear-gradient(150.93deg, #84fad5 1.02%, #ebbfff 44.33%, #f6ec85 76.07%) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
}

.price-modal-card:hover {
  box-shadow: 0 33px 20px rgba(0, 0, 0, 0.06), 0 7px 4px rgba(0, 0, 0, 0.01),
    0 2px 1px rgba(0, 0, 0, 0.01);
}

.carousel-control-next-icon {
  visibility: hidden;
}

.carousel:hover .carousel-control-next-icon {
  visibility: visible;
}

.span-hover:hover {
  color: #2b2d2d;
}

.span-hover-underline:hover {
  color: #2b2d2d;
  text-decoration: underline;
}

.carousel-control-prev-icon {
  visibility: hidden;
}

.carousel:hover .carousel-control-prev-icon {
  visibility: visible;
}

.pricing-text > h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  /* Gray 1 */
  margin-left: 0.7rem;
  color: #333333;
}

.price-text h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.price-modal-card > span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #828282;
}

.price-modal-card h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;

  color: black;
}

.price-modal-card h4 {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #333333;
}

.fulfill-price h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: black;
}

.price-subscribe-button {
  max-width: 192px;
  min-height: 36px;
  border: 2px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #f8ed84 7.35%, #f5e0ff 49.61%, #84fad5 92.65%);
  border-radius: 8px;
  display: inline-block;
}

.price-subscribe-button:hover {
  background: linear-gradient(
        297.06deg,
        #f8ed84 23.88%,
        #f5e0ff 66.2%,
        #84fad5 109.31%,
        white
      )
      padding-box,
    linear-gradient(297.06deg, #f8ed84 23.88%, #f5e0ff 66.2%, #84fad5 109.31%);
}

/*DashBoard Payment Modal CSS*/

.payment-form-container div {
  margin-bottom: 10px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  z-index: 2;
  padding: 10px 0 10px 0;
  background: #fefdfd;
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-radius: 5px;
}

.price-content {
  padding: 0 60px 0 60px;
}

.payment-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
}

.subscribe-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: black;
}

.payment-inputs {
  background-color: #fbfbfb;
  margin-bottom: 20px;
}

.input-cash {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.payment-button-confirm {
  width: 100%;
  min-height: 36px;
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  border-radius: 8px;
  color: #000000;
  display: inline-block;
  padding: 1px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
}

.amount-due {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  /* Gray 1 */

  color: #333333;
}

.cash-modal {
  border-radius: 20px;
}

.modal-default-close {
  position: absolute;
  color: #ffffff;
  font-size: 20px;
  opacity: 4;
  z-index: 10;
  cursor: pointer;
  top: -3%;
  left: 95%;
  transform: translate(-50%, -50%);
}

.carousel-outside-right-button {
  position: absolute;
  color: #ffffff;
  opacity: 4;

  cursor: pointer;
  right: -50%;
}

.carousel-outside-left-button {
  position: absolute;
  color: #ffffff;
  font-size: 20px;
  opacity: 4;
  cursor: pointer;
  left: -50%;
}

/*Drag Image Styles*/

.image-container {
  width: 100%;
  height: 325px;
}

/*Dashboard Icons && Modal Icons*/

.fa-close-icon {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/ant-design_close-circle-outlined.png");
  background-repeat: no-repeat;
}

.fa-close-icon {
  background-position: 0 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.fa-rightArrow-icon {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/chevron-right.png");
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  background-repeat: no-repeat;
}

.fa-rightArrow-icon {
  background-position: 0 0;
  width: 32px;
  height: 32px;
}

.fa-leftArrow-icon {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/chevron-left.png");
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  background-repeat: no-repeat;
}

.fa-leftArrow-icon {
  background-position: 0 0;
  width: 32px;
  height: 32px;
}

.fa-camera-icon {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/bi_camera.png");
  background-repeat: no-repeat;
}

.fa-camera-icon {
  background-position: 0 0;
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.fa-smile-icon {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/fontisto_slightly-smile.png");
  border-radius: 35px;
  background-repeat: no-repeat;
}

.fa-smile-icon {
  background-position: 0 0;
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.fa-teeny-icon {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/teenyicons_attach-outline.png");
  border-radius: 35px;
  background-repeat: no-repeat;
}

.fa-teeny-icon {
  background-position: 0 0;
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.fa-mastercard-icon {
  background: url("../assets/images/DashBoardImages/logos_mastercard.png")
    no-repeat right;
  background-position: right 10px center;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background-repeat: no-repeat;
}

.fa-mastercard {
  width: 50px;
  height: 18px;
}

.fa-payment-accept {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/accept-payment.png");
  border-radius: 50%;
  color: black;
  background-repeat: no-repeat;
}

.fa-payment-accept {
  background-position: 0 0;
  width: 60px;
  min-height: 60px;
}

.fa-payment-failed {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/failed.png");
  border-radius: 50%;
  color: black;
  background-repeat: no-repeat;
}

.fa-payment-failed {
  background-position: 0 0;
  width: 60px;
  min-height: 60px;
}

.fa-close-image {
  display: inline-block;
  background-image: url("../assets/images/DashBoardImages/close-image.png");
  color: white;
  background-repeat: no-repeat;
}

.fa-close-image {
  background-position: 10px 0;
  width: 77px;
  height: 30px;
}

.shadow-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
}

.gap-card {
  padding: 20px;
}
.gap-post {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.random-container {
  padding: 40px;
}

.random-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  /* Gray 2 */

  color: #4f4f4f;
}
.warning-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background: rgba(207, 189, 15, 0.2);
}

.random-title-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #4f4f4f;
}

.random-donation-details {
  width: 100%;
  height: 193px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding-left: 10px;
}

.random-header-span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Gray 1 */
  color: #333333;
}

.random-details-header {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Gray 3 */

  color: #828282;
}

.random-user-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Gray 1 */

  color: #333333;
}

.random-image {
  width: 100%;
  height: 210.78px;
}

/*FAQ*/

.faq-text {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #000000;
}

.faq-padding {
  margin: 0 230px 0 230px !important;
  border-top: 1px solid rgba(205, 214, 218, 0.25);
}

.faq-row-wrapper .faq-row {
  border-bottom: 1px solid rgba(205, 214, 218, 0.25) !important;
}

.faq-row .row-title-text {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #000000;
}

.row-content-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: yellow;
  border-top: 1px solid rgba(205, 214, 218, 0.25);
  padding: 10px !important;
}

/*AbousUs*/
.about-us-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.abous-us-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #535353;
  margin-bottom: 20px;
}

.abous-us-description li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #535353;
}

.border-radius-feedback {
  border-radius: 20px;
  padding: 48px 40px 48px 40px;
}

.about-us-padding {
  margin: 0 330px 0 136px !important;
}
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 40px;
  color: #a8a6a6;
  background: rgba(235, 235, 235, 0.2);
}
.arrow-container:hover {
  color: black;
  background: rgb(255, 255, 255, 0.5);
}
.close-container {
  cursor: pointer;
  top: 10px;
  left: 10px;
  z-index: 10;
}
.right-arrow-position {
  position: absolute;
  top: 50%;
  right: 10px;
}
.left-arrow-position {
  position: absolute;
  top: 50%;
  left: 10px;
}

@media screen and (max-width: 995px) {
  .big-image-popup > .fa-close-image {
    width: 108px !important;
  }

  .carousel-outside-right-button {
    right: 0 !important;
  }

  .carousel-outside-left-button {
    left: 0 !important;
  }

  .big-image-popup .fa-close-image {
    width: 108px !important;
  }
}

.test-buttons {
  display: none;
}

.mobile-buttons {
  width: 110px;
  height: 48px;
  /*custom-gradient*/
  background: linear-gradient(white, white),
    linear-gradient(
      160deg,
      rgb(132, 250, 213) 20%,
      rgb(235, 191, 255) 37%,
      rgb(246, 236, 133) 53%
    );
  border-radius: 10px;
  border: double 1px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 12px;
}

.mobile-buttons:hover {
  background: linear-gradient(
    320deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  font-weight: bold;
}

.active-button {
  background: linear-gradient(
    320deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  width: 110px;
  height: 48px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 12px;
}
.yellow-reminder {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  background: #f8ed84;
}

.public-profile-gradient-button-box {
  min-width: 163px;
}

.create-dream-container {
  width: 100%;
  min-height: 100vh;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 80px 20px;
}
.create-dream-back-btn {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-items: start;
  max-width: 760px;
  margin-bottom: 10px;
}

@media (max-width: 862px) {
  .buttons-container {
    flex-wrap: nowrap;
  }
}

@media (max-width: 770px) {
  .profile-angel-badge-container {
    margin-top: 6px;
  }
  .profile-name-fulfill-box-margin {
    margin-bottom: 26px;
  }
  .profile-name-fulfill-box {
    display: flex;
    flex-direction: column-reverse;
  }

  .profile-name-fulfill-box .m-2 {
    margin: 0 !important;
    align-items: center;
    height: 26px;
  }

  .profile-name-fulfill-box .justify-content-around {
    align-items: center;
    height: 16px;
    margin: 4px 0 20px !important;
  }

  .public-profile-gradient-button-box {
    min-width: 232px;
  }

  .wings-fulfilled-page-box {
    padding: 0 3px !important;
  }
}

@media screen and (max-width: 820px) {
  .padding-60-responsive {
    padding: 0;
  }

  .comment-form {
    justify-content: flex-end;
  }

  .test-buttons {
    display: flex;
    justify-content: space-around;
  }

  .display-mobile {
    display: none;
  }

  .price-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .price-modal-card {
    align-items: center;
  }

  .price-subscribe-button {
    width: 100%;
  }

  .price-modal-card {
    width: 60%;
  }
}

@media screen and (max-width: 912px) {
  .comment-form {
    justify-content: flex-end;
  }

  .text-size-it {
    font-size: smaller;
  }

  .text-end {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 995px) {
  .carousel-item {
    max-width: 100%;
  }

  .left-side-overflow {
    position: initial;
    /*min-height: 600px;*/
  }

  .right-side-overflow {
    position: initial;
  }

  .price-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .price-modal-card {
    align-items: center;
  }

  .price-subscribe-button {
    width: 100%;
  }

  .price-modal-card {
    width: 60%;
  }
}

.padding-responsive {
  padding: 20px 0 20px 20px;
}
.padding-15-responsive {
  padding: 20px 0 20px 20px;
}
.description-width {
  width: 550px;
}
.wing-avatar-notice {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.user-image:hover .user-name {
  text-decoration: underline;
}
@media screen and (max-width: 769px) {
  .description-width {
    min-width: 100%;
  }
  .mobile-card {
    padding: 10px;
  }
  .mobile-15-card {
    padding: 15px;
  }
  .padding-responsive {
    padding: 10px;
  }
  .padding-15-responsive {
    padding: 15px;
  }

  .carousel-item {
    max-width: 100%;
  }
  .left-card {
    display: none !important;
  }
  .left-side-overflow-post {
    position: unset;
    height: unset;
    overflow: unset;
  }
  .post-image {
    height: 230px;
  }
}

.margin-received-card {
  margin-bottom: 14px;
  padding-left: 7px;
  padding-right: 7px;
}
.navigation-item {
  border-radius: 8px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: #333333;
  cursor: pointer;
}
.new-item-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 20px;
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  padding: 6px 8px;
  border-radius: 8px;
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.31px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #333333;
}
.navigation-item-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.canvas-container {
  overflow: scroll;
  width: 360px !important;
}
.canvas-container-mobile {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 80px !important;
}

.card-progress-bar {
  height: 14px;
  cursor: pointer;
  border-radius: 8px;
}
.post-progress-bar {
  height: 10px;
  cursor: pointer;
  width: 100%;
}
.navigation-item:hover {
  background: #f5f5f5;
  color: black;
  font-weight: 700;
}
.fulfilled-by-container {
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.comment-line {
  border-top: 1px solid #f1f1f1;
  margin-top: 20px;
  padding-top: 20px;
}

@media screen and (min-width: 1350px) {
  .received-card {
    width: 183px;
  }
}
.notification-padding {
  padding: 20px;
}
.buttons-container-modal {
  flex-wrap: nowrap;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 450px) {
  .gap-card {
    padding: 10px;
    border-radius: 8px !important;
  }
  .comment-line {
    margin-top: 15px;
    padding-top: 15px;
  }
  .responsive-modal-input {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .fulfilled-by-container {
    padding-bottom: 15px;
  }
  .gap-post {
    gap: 15px;
  }
  .full-width {
    width: 100%;
  }
  .price-modal-card {
    width: 100%;
  }
  .notification-container {
    max-width: 350px;
    z-index: 1050 !important;
  }
  .notification-padding {
    padding: 15px;
  }
  .padding-post {
    padding: 0;
  }
  .padding-card {
    padding: 0;
  }
  .padding-left-card {
    padding: 0;
  }
  .canvas-container {
    width: 320px !important;
  }
}
.notification-container {
  width: 420px !important;
}

.size-modal {
  display: flex;
  width: 420px;
  height: 448px;
  margin: 0 !important;
  min-width: auto !important;
  min-height: auto !important;
}

.shadow-notifications {
  box-shadow: 0 33px 20px rgba(0, 0, 0, 0.06), 0 7px 4px rgba(0, 0, 0, 0.01),
    0 2px 1px rgba(0, 0, 0, 0.01) !important;
}

.search-nav-input {
  padding: 8px 10px;
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
}
.search-nav-input-mobile {
  padding: 8px 50px 8px 8px;
  height: 48px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #a8a6a6;
}
.search-nav-input-mobile::placeholder {
  color: black;
}
.close-button {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebebeb;
  border-radius: 40px;
  padding: 8px;
}

.search-nav-input:focus {
  border-color: #828282;
}
.search-nav-input-mobile:focus {
  border-color: #828282;
}

.padding-home-card {
  padding: 120px;
}
.notify-circle {
  position: absolute;
  background-color: #ebbfff;
  width: 10px;
  height: 10px;
  margin-bottom: 30px;
  margin-left: 30px;
  border-radius: 50%;
}
.notify-circle-card {
  display: flex;
  align-self: center;
  background-color: #ebbfff;
  padding: 5px;
  border-radius: 50% !important;
  width: 10px;
  height: 10px;
}
.notify-container {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  position: relative;
  cursor: pointer;
  border-radius: 26px;
  color: #b6b4b4;
  text-align: center;
}

.notify-container:hover {
  color: #333333;
  background-color: #ebebeb;
}
.notify-container:hover .navbarMessengerIcon path {
  stroke: #333333;
}
.notify-container:hover .notificationIcon path {
  fill: #333333;
}

.dropdown.show:before {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  z-index: 1;
}

.terms-padding {
  margin-left: 136px;
  margin-right: 345px;
  padding-bottom: 136px;
}
.as-read {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  font-size: 12px;
  color: #a8a6a6;
  padding: 8px;
  border-radius: 40px;
  cursor: pointer;
}
.as-read:hover {
  color: #333333;
}
.switch-container {
  width: 100%;
  background-color: #ebebeb;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.switch-button {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ebebeb;
  padding: 8px 20px;
  width: 50%;
}

#notifyLink .nav-link.active {
  font-weight: 700;
  background-color: #fff;
}
/*.switch-button:active {*/
/*    font-weight: 700;*/
/*    background-color: #FFF;*/
/*}*/
/*.switch-button:link {*/
/*    background-color: #FFF;*/
/*}*/

.font-size-h1 {
  font-size: 73px !important;
}

.font-size-h1 {
  font-size: 73px !important;
}

.carousel > .carousel-indicators > button {
  border: 0;
  background: rgb(108, 106, 106, 0.5);
  height: 6px;
  width: 6px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.carousel > .carousel-indicators > button.active {
  border: 0;
  width: 6px;
  background: #f1f1f1;
  opacity: 1;
  border-radius: 10px;
}
@media screen and (max-width: 1000px) {
  .padding-mobile {
    padding-top: 20px !important;
  }
}
@media screen and (max-width: 900px) {
  .padding-home-card {
    padding: 15px;
  }

  .about-us-padding {
    margin: 0 30px 0 20px !important;
  }

  .terms-padding {
    margin-left: 20px;
    margin-right: 30px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1300px) {
  .faq-padding {
    margin: 0 25px 0 25px !important;
    border-top: 1px solid rgba(205, 214, 218, 0.25);
  }
}

@media screen and (max-width: 400px) {
  .public-profile-gradient-button-box {
    min-width: 177px;
  }
  .row-resp {
    padding: 20px !important;
    padding-top: 5px !important;
  }

  .font-size-h1 {
    font-size: 50px !important;
  }
}
.mobile-comments-container {
  box-shadow: none;
  padding: 10px;
}
@media screen and (max-width: 575px) {
  .carousel-width-vw {
    width: 100vw;
  }
  .span-margin {
    margin-left: 10px;
  }
  .padding-10-angel {
    padding: 0 10px 0 10px;
  }
  .big-image-popup .fa-close-image {
    width: 115px !important;
  }
  .responsive-container {
    height: calc(100vh + 125px);
    box-shadow: none;
    padding: 10px;
  }
  .notification-arrow {
    right: 72px;
  }
}

@media screen and (max-width: 575px) {
  .image-slider {
    height: 200px;
  }
  .big-image-popup .fa-close-image {
    width: 122px !important;
  }
  .border-radius-feedback {
    padding: 20px;
  }
}
.popup-comment-list {
  padding-bottom: 60px;
  height: 100%;
}

.popup-comment {
  height: 100%;
  overflow: hidden;
}
.modal-comments-container {
  height: 85vh;
}
.width-set {
  max-width: 90vw;
}
@media screen and (max-width: 768px) {
  .popup-comment {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  .margin-posts {
    max-width: 100%;
  }
  .padding-left-card {
    max-width: 100%;
  }
  .modal-comments-container {
    height: 100vh;
  }
  .width-set {
    max-width: 100vw;
  }
  .modal-comment-image {
    height: 250px;
    border-radius: unset;
  }
  .popup-comment-list {
    height: auto;
    padding-bottom: 50px;
  }
}
.angel-dream-post-avatar {
  position: absolute;
  width: 165px;
  height: 165px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid white;
}
.gradient-border-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 173px;
  height: 173px;
  border-radius: 50%;
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
}
.gradient-bg {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: -0.56px;
  border-radius: 4px;
  background: linear-gradient(
    325deg,
    rgba(246, 236, 133, 0.4) 0%,
    rgba(235, 191, 255, 0.4) 70%,
    rgba(132, 250, 213, 0.4) 100%
  );
}
.gradient-bg-charity {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: -0.56px;
  background: linear-gradient(325deg, #fefada 0%, #fcf6ff 70%, #e4fbf6 100%),
    linear-gradient(160deg, #b8f8e3 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box;
  background-origin: border-box !important;
  border: 1px double transparent;
  border-radius: 8px;
  height: 38px;
}
@media screen and (max-width: 1024px) {
  .mobile-buttons-modal {
    flex-wrap: wrap;
  }
  .buttons-container-modal {
    justify-content: space-between;
  }
}
@media screen and (max-width: 768px) {
  .mobile-buttons-modal {
    flex-wrap: nowrap;
  }
  .buttons-container-modal {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 360px) {
  .public-main-image-margin {
    position: relative;
    margin-top: -7.3rem;
  }
}
@media screen and (max-width: 465px) {
  .angel-dream-post-avatar {
    width: 125px;
    height: 125px;
    border: 3px solid white;
  }
  .gradient-border-container {
    width: 132px;
    height: 132px;
  }
  .mobile-buttons-modal {
    flex-direction: column;
    align-items: start !important;
  }
}
input::-webkit-date-and-time-value {
  display: block;
  text-align: left !important;
}
