.modal:has(> .modal-scroll) {
  overflow: scroll !important;
  border-radius: 8px !important;
}

.edit-profile-header {
  height: 72px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  box-shadow: 0 8px 16px 0 #2224290d;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  position: -webkit-sticky;
  z-index: 1000;
}

.edit-profile-content {
  background-color: #f5f5f5;
  gap: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  padding-top: 20px;
}

.edit-profile-info-advise {
  height: 30px;
  padding: 10px 20px;
  gap: 10px;
  display: flex;
}

.edit-profile-info-advise span {
  line-height: 17.86px;
  letter-spacing: -0.04em;
  color: #6c6a6a;
}

.edit-profile-img-name {
  height: 230px;
  padding: 0px 20px 20px 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.edit-title-for-boxes {
  height: 20px;
  color: #252525;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
}

.edit-profile-img-container {
  height: 90px;
  padding: 0px 0px 10px 0px;
  user-select: none;
}

.profile-img-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2.13px solid #ffffff;
  position: relative;
  cursor: pointer;
}
.profile-img-round-box:hover .delete-icon-edit-profile {
  opacity: 1;
}
/* .edit-profile-img-container:hover .delete-icon-edit-profile{
  opacity: 1;
} */
.profile-img-round-box {
  position: relative;
  width: 80px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 2.13px 4.27px 0px #0000000f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-add-new-image,
.profile-add-new-image-dream {
  right: 22px;
  padding: 0;
  width: 32px;
  bottom: 22px;
  position: absolute;
}

.default-img-edit-profile {
  justify-content: center;
  align-items: center;
  display: flex;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.2) 1.02%,
    rgba(235, 191, 255, 0.2) 44.33%,
    rgba(246, 236, 133, 0.2) 76.07%
  );
  border: 2px solid white;
  margin: 0;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.edit-name-user-container {
  height: 60px;
  gap: 6px;
  display: flex;
}

.edit-first-last-name-box {
  height: 60px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.edit-first-last-name-box input {
  width: 277px;
  height: 40px;
  padding: 8px 9px 8px 9px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #e1e6ef;
  color: #333333;
}

.edit-name-user-container span,
.edit-profile-biography-box span,
.edit-profile-dream-container span {
  line-height: 17.86px;
  letter-spacing: -0.04em;
  color: #404040;
  height: 10px;
}

.edit-profile-biography-box {
  padding: 0px 20px 20px 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.countries-region-dropdown {
  width: 277px;
  color: #333333 !important;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e1e6ef !important;
}

.edit-profile-about {
  gap: 6px;
  flex-direction: column;
}

.edit-profile-textarea {
  margin-top: 6px;
  resize: none;
  padding: 8px 9px 8px 9px;
  border-radius: 8px;
  border: 1px solid #e1e6ef;
  color: #333333;
}

.edit-profile-dream-container {
  padding: 0px 10px 20px 10px;
  gap: 20px;
  flex-direction: column;
}

.profile-dream-img-round-box {
  position: relative;
  width: 80px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2.13px 4.27px 0px #0000000f;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-dream-img-circle {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  border: 2.13px solid #ffffff;
  position: relative;
  cursor: pointer;
}

.profile-edit-delete-image {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  padding: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 1px;
  text-align: center;
}

.edit-profile-dream-images-carousel-height {
  height: 90px;
}

.default-dream-img-edit-profile {
  justify-content: center;
  align-items: center;
  display: flex;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.2) 1.02%,
    rgba(235, 191, 255, 0.2) 44.33%,
    rgba(246, 236, 133, 0.2) 76.07%
  );
  border: 2px solid white;
  margin: 0;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.edit-profile-modal-footer {
  height: 60px;
  padding: 0px 20px 20px 20px;
  gap: 10px;
}

.profile-img-round-box:nth-child(1) {
  z-index: 5;
  margin: 0;
}

.profile-img-round-box:nth-child(2) {
  z-index: 4;
  margin-left: -10px;
}

.profile-img-round-box:nth-child(3) {
  z-index: 3;
  margin-left: -10px;
}

.profile-img-round-box:nth-child(4) {
  z-index: 2;
  margin-left: -10px;
}

.profile-img-round-box:nth-child(5) {
  z-index: 1;
  margin-left: -10px;
}
.profile-dream-img-round-box:hover .delete-icon-edit-profile {
  opacity: 1;
}

.profile-dream-img-round-box .visibility-for-mobile-delete {
  opacity: 1;
}

@media (min-width: 600px) {
  .modal-dialog.modal-600 {
    max-width: 600px;
    margin: auto;
  }
}

@media (max-width: 600px) {
  .modal-dialog.modal-600 {
    max-width: 100%;
    margin: auto;
  }
  .edit-profile-content {
    gap: 10px !important;
    padding-top: 10px;
  }
  .edit-first-last-name-box input {
    width: inherit !important;
  }
  .edit-name-user-container {
    flex-direction: column;
  }
  .countries-region-dropdown {
    width: inherit !important;
  }
  .edit-profile-biography-box,
  .edit-profile-img-name {
    height: inherit !important;
    padding: 0 10px 20px 10px;
  }
  .edit-name-user-container {
    height: inherit !important;
  }
  .profile-edit-country-region {
    flex-direction: column;
    gap: 6px;
  }
  .profile-img-round-box {
    margin-right: 4px !important;
    margin-left: 0 !important;
    width: 70px;
    height: 70px;
  }
  .profile-img-circle {
    width: 70px;
    height: 70px;
  }
  .default-img-edit-profile {
    width: 70px;
    height: 70px;
  }
  .profile-add-new-image {
    right: 17px;
    bottom: 17px;
  }
  .edit-profile-modal-footer .gradient-button {
    width: 149px !important;
  }
}
