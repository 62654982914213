@media screen and (max-width: 1400px) {
  .messenger-container {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .message-container {
    max-width: 450px;
  }
}
@media screen and (max-width: 900px) {
  .message-container {
    max-width: 350px;
  }
  .messenger-page-container {
    padding: 0;
    width: 100%;
    height: 100svh;
    overflow: hidden;
    position: fixed;
    bottom: 0 !important;
    bottom: env(safe-area-inset-bottom);
  }
  .saved-dream,
  .saved-charity-card-container {
    width: 90%;
  }
}
@media screen and (max-width: 660px) {
  .search-modal-container {
    max-width: 660px;
  }
  .search-bootstrap-modal {
    padding: 0 28px;
  }
}

@media screen and (max-width: 600px) {
  .chat-list-padding,
  .no-chats-container,
  .nothing-saved-container {
    padding: 10px;
  }
  .message-container {
    max-width: 315px;
  }
  .chat-input-container {
    padding: 10px;
  }
  .list-header-container {
    height: 48px;
    min-height: 48px;
  }
  .chat-list-container {
    height: calc(100% - 124px);
  }
  .chat-list-container-request-height {
    height: calc(100% - 48px);
  }
  .saved-section-chat {
    padding: 0px 10px 10px 10px;
  }

  .saved-section-header {
    justify-content: start;
    flex-direction: row-reverse;
    padding: 10px;
    gap: 10px;
    height: 50px;
  }
  .dream-angel-profile-container {
    margin-bottom: 30px;
  }
  .chat-list,
  .chat-container {
    width: 100%;
    height: 100%;
  }
  .user-section-chat {
    padding: 10px;
  }
}
@media screen and (max-width: 375px) {
  .dream-angel-profile-container {
    width: 100%;
    padding: 0;
  }
  .dream-angel-profile-btn-container {
    width: 90%;
  }
  .dream-angel-profile-description {
    width: 90%;
  }
  .dream-angel-gradient-btn {
    min-width: 130px !important;
  }
  .saved-dream,
  .saved-charity-card-container {
    width: 280px;
  }
  .search-bootstrap-modal {
    padding: 0;
  }
  .search-modal-title > span {
    font-size: 15px;
    line-height: 19.14px;
  }
  .search-modal-container {
    padding: 10px;
  }
}
