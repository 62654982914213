.user-balance {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 14px 6px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(150deg, #84fad5 7.35%, #ebbfff 49.61%, #f6ec85 92.65%)
      border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  cursor: default;
}
.user-balance-nav {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 6px 12px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(150deg, #84fad5 7.35%, #ebbfff 49.61%, #f6ec85 92.65%)
      border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  cursor: default;
}
.user-balance-nav h5 {
  font-size: 12px;
  color: #a8a6a6;
  line-height: 1;
}
.user-balance h5 {
  font-size: 12px;
  color: #a8a6a6;
}

.current-balance {
  width: 100%;
  height: 14px;
  text-align: center;
}

.user-balance-footer {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 0;
  color: #333333;
}

.user-balance-footer h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #333333;
}

.user-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.language-selector {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  cursor: pointer;
}

.fa-star-icon {
  display: inline-block;
  background-image: url("../assets/images/navbar/StarIcon.png");
  background-color: white;
  background-repeat: no-repeat;
}

.fa-star-icon {
  background-position: 0 0;
  width: 10px;
  height: 10px;
}

.fa-bell-icon {
  display: inline-block;
  background-image: url("../assets/images/navbar/carbon_notification.svg");
  background-color: white;
  background-repeat: no-repeat;
}

.fa-bell-icon {
  background-position: 0 0;
  width: 20px;
  height: 22px;
}
.fa-logo-icon {
  width: 100px;
  height: 50px;
  color: black;
}
.fa-logo-icon-steps {
  width: 88px;
  height: 32px;
  color: black;
}

@media screen and (max-width: 750px) {
  .user-balance-nav {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .desktop-search {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .mobile-search-container {
    display: none;
  }
}
