.like-icon-default-styles:hover {
  stroke: black;
  fill: #f5f5f5;
}

.fuflilled-by-name-box {
  align-items: baseline;
  gap: 4px;
}

.dream-page-progress-container {
  height: 15px;
}

@media (max-width: 465px) {
  .fuflilled-by-name-box {
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
.fulfilled-by-name-badge-box {
  display: flex;
  align-items: center;
  gap: 5px;
}
