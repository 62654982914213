.steps-page-container {
  width: 100%;
  min-height: 100vh;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px 0;
}
.step-1-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background-color: white;
  padding: 30px 20px 60px 20px;
  box-shadow: 0px 4px 8px 0px #0000000f;
  width: 560px;
}

.step-header-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
}
.step-title {
  font-family: Caveat;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: black;
  text-align: center;
  margin-top: 20px;
}
.step-protip {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #6c6a6a;
  margin-top: 10px;
}
.step-protip-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #6c6a6a;
}
.step-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  gap: 10px;
  height: 30px;
}
.step-number-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #828282;
  white-space: nowrap;
}
.step-header-line {
  width: 100%;
}
.step-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 100%;
  gap: 30px;
  max-width: 360px;
}
.upload-photos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.upload-info {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #6c6a6a;
}
.upload-btn {
  max-width: 145px;
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}
.rounded-pictures-white-bg {
  background: white;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1.95px 7.8px 0px #0000000f;
  padding: 1.95px;
  position: relative;
}
.rounded-pictures {
  width: 100%;
  height: 100%;
  border-radius: 80px;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.2) 1.02%,
    rgba(235, 191, 255, 0.2) 44.33%,
    rgba(246, 236, 133, 0.2) 76.07%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-image-uploaded {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
}
.rounded-pictures:hover > .profile-image-uploaded-deleted {
  opacity: 1;
}
.profile-image-uploaded-deleted {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 1px;
  text-align: center;
}
.uploaded-pictures {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.rounded-pictures-white-bg:nth-child(1) {
  margin: 0px;
  z-index: 5;
}
.rounded-pictures-white-bg:nth-child(2) {
  margin-left: -10px;
  z-index: 4;
}
.rounded-pictures-white-bg:nth-child(3) {
  margin-left: -10px;
  z-index: 3;
}
.rounded-pictures-white-bg:nth-child(4) {
  margin-left: -10px;
  z-index: 2;
}
.rounded-pictures-white-bg:nth-child(5) {
  margin-left: -10px;
  z-index: 1;
}
.textarea-title {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black;
}
.profile-description-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  margin-top: 24px;
  max-width: 360px;
}
.profile-textarea {
  resize: none;
  padding: 8px 9px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  width: 100%;
  min-height: 200px;

  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #6c6a6a;
}
.profile-textarea-error {
  border: 1px solid #e44747;
}
.input-file-upload {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.email-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.disabled-email-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e1e6ef;
  background-color: #f1f3f9;
  padding: 8px 9px;
}
.disabled-textarea-input {
  resize: none;
  padding: 8px 9px;
  border: 1px solid #e1e6ef;
  background-color: #f1f3f9;
  border-radius: 8px;
  width: 100%;
  min-height: 200px;
}
.disabled-input-description {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #828282;
}
.flag {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.phone-number-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.insert-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
}
.number-prefix {
  border-radius: 8px;
  border: 1px solid #ebebeb;
  height: 40px;
  min-width: 57px;
  max-width: 145px;
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 4px;
  gap: 4px;
}
.prefix-number-input {
  height: 100%;
  width: auto !important;
  min-width: 23px;
  max-width: 125px;
  outline: none;
  border: none;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #828282;
}
.number-input-container {
  height: 40px;
  width: 85%;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  align-items: center;
  display: flex;
  padding: 8px 9px;
  justify-content: space-between;
}
.number-input-focus {
  border: 2px solid #ebbfff !important;
}
.number-input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #828282;
}

.phone-icon {
  width: 12px;
  height: 12px;
}
.search-dropdown-container {
  position: absolute;
  width: 100%;
  max-height: 296px;
  max-width: 360px;
  border-radius: 8px;
  border: 1px solid #b4b5b6;
  box-shadow: 0px 1px 2px 0px #00000026;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.dropdown-card-container:hover {
  background-color: #f5f5f5;
}
.dropdown-card-container {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
  gap: 20px;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
}
.country-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.country-name {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  text-align: left;
  color: black;
}
.custom-calendar-select-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.dropdown-bg-calendar {
  position: absolute;
  width: 100%;
  max-width: 360px;
  height: auto;
  margin-top: 1px;
  border-radius: 8px;
  border: 1px solid #b4b5b6;
  box-shadow: 0px 2px 2px 0px #00000026;
  background-color: #f1f3f9;
  padding: 15px 12px;
  display: flex;
  flex-direction: column;
}

.months-years-container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.month-navigation-btn {
  width: 40px;
  height: 40px;
  border-radius: 80px;
  box-shadow: 0px 1px 1px 0px #0000000d;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.calendar-body {
  width: 100%;
  padding: 0 22px;
}
.calendar-weekdays {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.week-day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 40px;

  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #1f1f1f;
}

.calendar-dates {
  display: flex;
  flex-wrap: wrap;
  width: 292px;
  gap: 2px;
}

.calendar-dates > .active {
  box-shadow: 0px 1px 1px 0px #000e330d;
  border-radius: 6px;
  background-color: white;
  color: #1f1f1f;
}
.calendar-dates > .selected {
  box-shadow: 0px 1px 1px 0px #000e330d;
  border-radius: 6px;
  background-color: black;
  color: white;
}
.calendar-dates > div {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 2px;

  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #828282;
}
.month-select-container {
  height: 32px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 1px 1px 0px #0000000d;
  padding: 8px 10px;
  cursor: pointer;
  position: relative;
}
.month-year-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.selected-option {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #141414;
}
.selected-option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected-triangle {
  height: 16px;
  display: flex;
}
.month-select-dropdown {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  padding: 12px;
  background-color: white;
  border: 1px solid #ebbfff;
  box-shadow: 0px 1px 2px 0px #000e3326;
  overflow-y: scroll;
  max-height: 224px;
  gap: 10px;
  top: 0;
  margin-top: 35px;
  margin-left: -10px;
}
.month-select-dropdown-item {
  height: 32px;
  width: 111px;
}
.not-selected-option {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #828282;
}
.gray-description {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #828282;
}

.step-4-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background-color: white;
  padding: 40px 20px 60px 20px;
  box-shadow: 0px 4px 8px 0px #0000000f;
  width: 760px;
  gap: 20px;
  height: max-content;
  max-height: 1172px;
}

.step-4-container .step-header-container {
  max-width: 560px;
}

.create-dream-bottom-container {
  max-width: 560px;
  width: 100%;
  height: 206px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-dream-continue-angel-box {
  width: 100%;
  height: 66px;
  max-width: 360px;
  padding: 0px 4px 2px 4px;
  gap: 4px;
  border-radius: 4px;
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #333333;
}

.button-font-details {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.button-font-details:hover {
  font-weight: 700 !important;
}

.step-5-container {
  max-width: 1160px;
  width: 100%;
  height: max-content;
  padding: 30px 40px 60px 40px;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 8px 0px #0000000f;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.step-5-container .step-header-container {
  max-width: 100%;
  margin: auto;
}

.step-5-container .step-protip-text {
  max-width: 760px;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  line-height: 22.4px;
}

.step-5-container .step-title {
  font-family: Caveat;
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.step-4-container .step-title {
  font-family: Caveat;
  font-size: 44px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.payment-cards {
  width: 240px;
  min-height: 490px;
  padding: 30px 10px 30px 10px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: linear-gradient(
    0deg,
    rgba(245, 196, 245, 0.06) 0%,
    rgba(227, 221, 162, 0.06) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.payment-cards-content {
  height: auto;
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 22px;
}

.description-points-list {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6c6a6a;
}

.description-points-list ul {
  padding-left: 1rem !important;
}

.description-points-element {
  padding-bottom: 8px;
}

.payment-cards-title {
  font-family: Caveat;
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #d7abeb;
}

.payment-cards-amount {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
}

.payment-cards-top-container {
  width: 226px;
  height: 80px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.payment-cards-top-container img {
  display: flex;
  height: 16px;
  margin-right: 2px;
}

.most-chose-payment {
  position: relative;
  border: 1px solid #b0e8e8 !important;
}

.most-chosen-label {
  width: 98px;
  height: 28px;
  padding: 4px 6px 4px 6px;
  gap: 10px;
  border-radius: 8px;
  background: #b0e8e8;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  position: absolute;
  top: -15px;
  left: 70px;
}

.payment-carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-cards-scroll {
  overflow-x: scroll;
  padding: 20px 0;
  display: flex;
  align-items: stretch;
  gap: 10px;
}
.payment-cards-scroll > * {
  user-select: none;
}
.payment-carousel-arrow-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  pointer-events: none;
}

.payment-cards-scroll-left-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 63%);
  user-select: none;
  z-index: 100;
  pointer-events: auto;
}
.payment-cards-scroll-right-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 63%);
  user-select: none;
  z-index: 100;
  pointer-events: auto;
}
.payment-cards-scroll-icon {
  cursor: pointer;
}
.step-5-container a {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7ec6ce;
}

.step-5-container a:hover {
  color: #428a92;
  text-decoration: underline;
}

.dream-architect-modal {
  width: 100%;
  max-width: 360px;
  border-radius: 8px;
}

.dream-architect-modal .modal-content {
  border: 2px solid #b0e8e8;
}

.dream-architect-modal-header {
  width: 360px;
  height: 56px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #828282;
}

.dream-architect-modal-header img {
  border-radius: 500px;
  box-shadow: 0px 8px 16px 0px #2224290d;
  cursor: pointer;
  position: absolute;
  right: 16px;
}

.dream-architect-modal-body {
  width: 360px;
  height: 498px;
  padding: 30px 20px;
  gap: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
}

.dream-architect-modal-top {
  width: 320px;
  height: 286px;
}

.dream-architect-modal-stars {
  width: 320px;
  height: 162px;
}

.dream-architect-modal-stars .payment-cards-title {
  font-size: 40px;
  font-family: Caveat;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 8px;
}

.dream-architect-description {
  margin-top: 10px;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6c6a6a;
}

.dream-architect-modal-amount-insert {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dream-architect-modal-amount-insert .error-info-container {
  height: 18px !important;
  padding: 0px 4px 2px 4px;
  border-radius: 4px;
}

.amount-insert-box {
  width: 320px;
  height: 56px;
  padding: 30px 10px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount-insert-box-error {
  border: 1px solid #e44747;
}

.amount-insert-box span {
  font-family: Caveat;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #252525;
}

.amount-insert-box input {
  height: 36px;
  padding: 9px 11px;
  gap: 8px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #b4b5b6;
  margin-right: 10px;
}

.amount-insert-box input:focus {
  padding: 8px 10px;
  border: 2px solid #ebbfff;
}

.dream-architect-modal-bottom {
  width: 320px;
  height: 72px;
  gap: 14px;
}

.dream-architect-modal-bottom p {
  margin-top: 14px;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}

.dream-architect-modal-bottom p span {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6c6a6a;
  padding-left: 7px;
}

.dream-pictures-white-bg {
  background: white;
  width: 74px;
  height: 74px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1.95px 7.8px 0px #0000000f;
  padding: 2px;
  position: relative;
}

.dream-image-uploaded {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 8px;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 8px 0px #0000000f;
}

.dream-square-pictures {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.2) 1.02%,
    rgba(235, 191, 255, 0.2) 44.33%,
    rgba(246, 236, 133, 0.2) 76.07%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.dream-square-pictures:hover > .profile-image-uploaded-deleted {
  opacity: 1;
}
.dream-pictures-white-bg:nth-child(1) {
  margin: 0px;
  z-index: 1;
}
.dream-pictures-white-bg:nth-child(2) {
  margin-left: -10px;
  z-index: 3;
}
.dream-pictures-white-bg:nth-child(3) {
  margin-left: -10px;
  z-index: 5;
}
.dream-pictures-white-bg:nth-child(4) {
  margin-left: -10px;
  z-index: 4;
}
.dream-pictures-white-bg:nth-child(5) {
  margin-left: -10px;
  z-index: 2;
}
.payment-card-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.description-points-list-container {
  margin: 0;
}
.payment-card-expire-time {
  margin-bottom: 15px;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  color: black;
}
.price-modal-container {
  padding: 20px 0px;
}
.price-modal-info {
  margin: 0px 20px 30px 20px;
}

.payment-modal-bootstrap > .modal-dialog {
  max-width: 560px;
}
.payment-modal-container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.payment-close-icon {
  cursor: pointer;
}
.payment-close {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 40px;
}

.payment-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 360px;
  margin-top: 8px;
}
.payment-title {
  font-family: Caveat;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}
.payment-description {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6c6a6a;
}
.payment-text-btn {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}
