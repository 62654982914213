.card {
    background: $card-bg;
    .card-header, .card-footer {
        background-color: $card-bg;
        border-bottom-color: $border-color;
    }
}
.card-post-toolbar {
    .dropdown-menu {
		a {
            border-color: $border-color;
            &:hover {
                background-color: $body-bg;
                color: black;
            }
		}
        .dropdown-item {
            color: $body-color;
        }
	}
    .dropdown-toggle {
        i {
            color: $body-color;
        }
    }
}

.card-footer{
    border-top: 1px solid $border-color;
}