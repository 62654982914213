@media screen and (max-width: 500px) {
  .dream-card-mobile.react-multi-carousel-item {
    padding: 0 10px;
  }
}

.dream-makers-title {
  font-family: Space Grotesk;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}

.dream-makers-bootstrap-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.dream-makers-modal-container {
  max-width: 300px;
  margin: 0 auto;
  max-height: 380px;
  border-radius: 8px;
  width: 300px;
  height: max-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px 0px #0000000f;
}
.dream-makers-bootstrap-modal > .modal-dialog {
  width: 300px;
  max-width: 300px;
}

.dream-makers-modal-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 14px 20px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px -11.5px 7.6px 0px #0000000d;
  box-shadow: -30px 70px 64.3px 0px #00000015;
}
.dream-makers-modal-card {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  gap: 10px;
}

.dream-makers-modal-results {
  width: 100%;
  padding: 20px 20px 0px 20px;
  gap: 14px;
  height: 320px;
  max-height: 320px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 320px) {
  .dream-makers-modal-container {
    width: 100%;
  }
}
