.landing-card {
  height: 302px !important;
  max-width: 253px !important;
  margin: 5px 5px 10px 5px;
  width: inherit;
  gap: 20px;
}

.public-dream-card-box-landing {
  max-width: 253px;
  height: 122px;
  padding: 0px 20px;
  gap: 20px;
}

.profile-bg-card-landing {
  max-width: 253px;
  height: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 0 0;
}

.public-dream-user-img-card-landing {
  height: 100px;
  width: 100px;
  line-height: 100px;
  object-fit: cover;
  padding: 2px;
}

@media screen and (max-width: 1024px) {
  .landing-card,
  .carousel-section-item {
    max-width: 212px !important;
  }
  .profile-bg-card-landing {
    max-width: 212px !important;
  }
  .public-dream-card-box-landing {
    max-width: 212px !important;
    height: 122px;
    padding: 0px 20px 0px 20px;
    gap: 20px;
  }
}

@media screen and (max-width: 660px) {
  .landing-card {
    padding: 0px 0px 30px 0px;
    max-width: 240px !important;
    gap: 20px;
    margin: 5px 4px 10px 4px;
  }
  .carousel-section-item {
    width: 240px !important;
    margin: 5px 4px 10px 4px;
  }
  .profile-bg-card-landing {
    max-width: 240px !important;
  }
  .public-dream-card-box-landing {
    max-width: 240px !important;
    height: 122px;
    padding: 0px 20px 0px 20px;
    gap: 20px;
  }
  .arrows-landing-carousel-section {
    display: none;
  }
}
