@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf");
}

* {
  box-sizing: border-box;
}

p h3 h1 span {
  font-family: "Roboto";
  font-style: normal;
}

.login-header {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  background: linear-gradient(
      114.93deg,
      #84fad5 1.02%,
      #ebbfff 44.33%,
      #f6ec85 76.07%
    )
    left bottom white no-repeat;
  background-size: 100% 1px;
  color: white;
  margin: auto;
  text-decoration: none;
}

.gradient-login-bg {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(107, 228, 190, 0.14) 10%,
    rgba(245, 196, 245, 0.14) 50%,
    rgba(227, 221, 162, 0.14) 90%
  );
}

.box {
  padding: 15px;
  backdrop-filter: blur(10px);
  max-width: 400px;
  height: auto;
  border-left: 2px solid rgb(132, 250, 213);
  border-right: 2px solid rgb(246, 236, 133);
  background-image: linear-gradient(
      90deg,
      rgb(132, 250, 213),
      rgb(235, 191, 255),
      rgb(246, 236, 133)
    ),
    linear-gradient(
      90deg,
      rgb(132, 250, 213),
      rgb(235, 191, 255),
      rgb(246, 236, 133)
    );
  background-size: 100% 2px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
  line-height: 30px;
}

.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  gap: 10px;
}

.form-header-title {
  padding: 10px;
  font-family: Caveat;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: #000000;
}

.form-header-title-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.form-header-title-container p {
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #6c6a6a;
}

.form-body {
  width: 360px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.login-form-input-box {
  height: 88px;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  gap: 4px;
}

.form-body h3 {
  color: #000000;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
}

.form-body span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
}

.form-body label {
  margin-bottom: 0 !important;
}

.form-body a {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7ec6ce;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.04em;
  text-align: left;
}

.login-pages-container input:focus {
  border: 2px solid #ebbfff !important;
}

.input-error {
  border: 2px solid #e44747 !important;
}

.input-complete {
  border: 1px solid #b6b4b4 !important;
}

#password {
  height: 40px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}

#email {
  height: 40px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}

#confirmedPassword {
  height: 40px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
}

.login-form-submit-button {
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  gap: 10px;
  border-radius: 8px;
  padding: 0;
  background: linear-gradient(325deg, #f8ed84, #f5e0ff 70%, #84fad5),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box;
  background-origin: border-box !important;
  border: 1px double #0000;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  font-family: Space Grotesk;
  color: #000 !important;
}

.login-form-submit-button:hover {
  background: linear-gradient(325deg, #f6ec85, #ebbfff 70%, #84fad5);
  font-weight: 700;
}

.login-form-disabled {
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  gap: 10px;
  border-radius: 8px;
  padding: 0;
  background: #f5f5f5;
  color: #b6b4b4;
  border: 1px solid #f1f1f1;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  color: #b6b4b4;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  font-family: Space Grotesk;
}

.register-form-button-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-box-bottom-text {
  text-align: center !important;
  line-height: 14px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #6c6a6a;
}

.content-box-error {
  margin-top: -10px;
}

.register-info-box div {
  display: flex;
  align-items: center;
  width: 360px;
  height: 24px;
  gap: 4px;
  border-radius: 4px;
}

.register-info-box img {
  width: 16px;
  height: 16px;
}

.register-info-box span {
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1d2433;
}

/*check-email-css*/

.screen-size {
  flex: 1;
}

.login-pages-container {
  margin: 20px 0;
  width: 560px;
  padding: 30px 20px 60px 20px;
  gap: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px;
  box-shadow: 0px 4px 8px 0px #0000000f;
}

.check-email-content {
  margin: 10px 0;
  width: 360px;
  height: 208px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  gap: 20px;
}

.check-email-title {
  padding: 10px;
}

.check-email-title div {
  font-family: Caveat;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #000;
}

.check-email-message {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #6c6a6a;
}

.check-email-message span {
  text-decoration: underline;
  cursor: pointer;
}

/*reset-password-css*/
.forgot-password-top-box {
  height: 148px;
  width: 360px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.forgot-passowrd-title {
  padding: 10px;
  font-family: Caveat;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #000;
}

.forgot-password-description {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #6c6a6a;
}

.forgot-password-main-box {
  height: 158px;
  width: 360px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password-input-box {
  width: 360px;
  padding: 10px 0px;
}

.forgot-password-input-box p {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000;
  margin-bottom: 4px;
}

.forgot-password-input-box input {
  width: 100%;
  height: 40px;
  padding: 8px 9px 8px 9px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
}

.reset-check-email-box {
  width: 560px;
  height: 322px;
  margin: 20px 0;
  padding: 30px 20px 60px 20px;
  gap: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reset-check-email-top {
  height: 126px;
  width: 360px;
}

.reset-check-email-main {
  height: 86px;
  width: 360px;
  gap: 10px;
}

.reset-check-email-main p {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #6c6a6a;
}

.reset-check-email-main div {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #7ec6ce;
}

.width-360 {
  width: 360px;
}

@media screen and (max-width: 990px) {
  .resp-img {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .mobile-width-hundred,
  .register-info-box div {
    width: 100% !important;
  }

  .login-pages-container {
    width: 100% !important;
    padding: 30px 10px 60px 10px;
    gap: 20px;
  }

  .width-360 {
    width: 100%;
  }

  .form-body {
    width: 100%;
  }

  .check-email-container {
    width: 100%;
    padding: 30px 10px 60px 10px;
    gap: 20px;
    border: 1px;
  }
  .check-email-content {
    width: auto !important;
    max-width: fit-content !important;
  }
}

@media screen and (max-width: 600px) {
  .login-header {
    display: none;
  }

  .login-content {
    height: 100vh;
  }
}

.fa-twitter-icon {
  display: inline-block;
  background-image: url("../assets/images/loginImages/icons8-instagram.png");
  background-color: white;
}

.fa-twitter-icon {
  background-position: 0 0;
  width: 32px;
  height: 32px;
}

.fa-instagram-icon {
  display: inline-block;
  background-image: url("../assets/images/loginImages/icons8-twitter.png");
  background-color: white;
}

.fa-instagram-icon {
  background-position: 0 0;
  width: 32px;
  height: 32px;
}

.fa-facebook-icon {
  display: inline-block;
  background-image: url("../assets/images/loginImages/icons8-facebook.png");
  background-color: white;
}

.fa-facebook-icon {
  background-position: 0 0;
  width: 32px;
  height: 32px;
}

.text-blue {
  color: #7ec6ce !important;
  display: inline-block;
  margin-left: 3px;
  cursor: pointer;
}

.login-box-bottom-text .text-blue {
  margin-left: 10px;
}
