.current-comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.message-formatted {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.first-comment {
  display: flex;
  flex-direction: row;
}
.avatar-container {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar-line {
  height: 100%;
  margin-top: 5px;
  width: 1px;
  background-color: #ebebeb;
}
.avatar-line-angel {
  margin-right: 4px;
}
.current-replies {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.replies-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reply-line {
  display: flex;
  justify-content: end;
  padding-right: 5px;
  width: 40px;
  height: 20px;
}

.reply-line-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.reply-input {
  margin-left: 40px;
}
.rounded-line {
  width: 50%;
  height: 100%;
  border-bottom-left-radius: 100%;
  border: 1px solid #ebebeb;
  border-right-color: transparent;
  border-top-color: transparent;
}
.small-line {
  width: 50%;
  height: 100%;
  border-right-style: solid;
  border-right-color: #ebebeb;
  border-right-width: 1px;
  margin-left: 6px;
}
.comment-reply {
  width: 100%;
  padding-top: 2px;
}
.second-line-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35px;
}
.continued-line {
  height: 100%;
  width: 1px;
  background-color: #ebebeb;
}
.gradient-border {
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  border-radius: 50%;
  padding: 2px;
}

.gradient-border-notification {
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  width: 45px;
  height: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-notification {
  border-radius: 50px;
}
