.container-for-profile-content {
  padding-bottom: 20px;
  margin-bottom: -20px;
  background-color: rgba(241, 241, 241, 1);
}

.dream-photo-container {
  background-color: white;
  width: 860px;
  height: 283px;
  padding: 20px;
  gap: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
}

.dream-photo-title {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.97px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
}
.dream-profile-photo-title {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.97px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 1);
}

.center-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.fulfill-button-profile {
  width: 173px;
  height: 40px;
  border-radius: 8px;
  border: 1px;
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  align-items: center;
  justify-content: center;
}

.fulfill-button-profile,
.message-button-profile p {
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  color: rgba(0, 0, 0, 1);
}

.message-button-profile:hover {
  background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
}
.message-button-profile {
  width: 117px;
  height: 40px;
  background: linear-gradient(white, white),
    linear-gradient(114.93deg, #84fad5 1.02%, #ebbfff 44.33%, #f6ec85 76.07%);
  border: 1px solid;
  border-radius: 8px;
  border: double 1px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  cursor: pointer;
  align-items: center;
}

.dream-scope-bar {
  height: 42px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.dream-bar-box {
  height: 15px;
  gap: 5px;
}

.dream-bar-box p {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: rgba(0, 0, 0, 1);
}

.dream-progress-bar-style {
  background-color: rgba(241, 241, 241, 1);
  height: 10px;
  cursor: pointer;
  border-radius: 8px;
  width: 779px;
}

.dream-progress-bar-style:hover {
  border: 1px solid #b6b4b4;
}

.dream-scope-fulfilled {
  height: 17px;
}

.dream-scope-boxes {
  gap: 4px;
  display: flex;
  align-items: center;
}

.dream-scope-boxes p {
  font-size: 13px;
  line-height: 16.59px;
  color: rgba(64, 64, 64, 1);
}

.dream-description-content {
  line-height: 17.86px;
  letter-spacing: -0.04em;
  color: rgba(64, 64, 64, 1);
  overflow: scroll;
  margin-bottom: 20px;
}

.dream-social-parts {
  height: 63px;
  gap: 10px;
  flex-direction: column;
}

.public-dream-social-parts {
  height: 63px;
  gap: 10px;
  flex-direction: column;
}

.dream-social-action {
  height: 36px;
  padding: 6px 0px 6px 0px;
}

.socials-left,
.socials-right {
  width: 68px;
  height: 24px;
  gap: 20px;
  display: flex;
}

.socials-right {
  justify-content: end;
}
.newsfeed-socials-left {
  width: 112px;
  height: 24px;
  gap: 20px;
  display: flex;
}

.newsfeed-socials-right {
  height: 24px;
  gap: 20px;
  display: flex;
}

.dream-stats-row div {
  gap: 4px;
}

.dream-stats-row p {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: left;
  color: rgba(182, 180, 180, 1);
  white-space: nowrap;
}

.dream-profile-first-container {
  color: rgba(64, 64, 64, 1);
  padding-right: 5px;
  border-right: 1px solid rgba(241, 241, 241, 1);
}

.dream-profile-first-container p {
  color: rgba(64, 64, 64, 1);
}

.dream-profile-second-container {
  padding: 0 5px;
  border-right: 1px solid rgba(241, 241, 241, 1);
}

.dream-profile-third-container {
  padding-left: 5px;
}
.dream-profile-photos-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.dream-title-edit-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.new-carousel-dream-images {
  height: 100%;
  width: 100%;
}

.new-carousel-dream-item {
  height: auto;
}

.dream-comments-box {
  width: 860px;
  max-height: 661px;
  background-color: white;
  padding: 20px;
  gap: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
}

.public-dream-photo {
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  width: 197.5px;
  height: 200px;
}
.carousel-about-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-about-item:hover .delete-icon-dream-photo {
  opacity: 1;
}
.carousel-dream-image {
  width: 197.5px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-dream-image:hover .delete-icon {
  opacity: 1;
}
.new-carousel-dream-item:hover .delete-icon-dream-photo {
  opacity: 1;
}
.dream-photo-images-new {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.dream-maker-angel-span {
  letter-spacing: -0.04em;
  color: #000000;
}

.dream-maker-fulfilled-box {
  margin-bottom: 10px;
}

.dream-maker-fulfilled-box div {
  font-family: Space Grotesk;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.dream-maker-img-box {
  height: 100px;
}

.default-img-public-dream {
  justify-content: center;
  align-items: center;
  display: flex;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.2) 1.02%,
    rgba(235, 191, 255, 0.2) 44.33%,
    rgba(246, 236, 133, 0.2) 76.07%
  );
}

.dream-maker-dream-description {
  margin-top: 5px;
  font-family: Space Grotesk;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #b6b4b4;
}

.dream-maker-angel-box {
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  width: 78px;
  height: 78px;
  padding: 3px;
}

.dream-maker-main-gap {
  gap: 30px;
}

.dream-maker-angel-main-gap {
  gap: 40px !important;
}

.dream-maker-avatar {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 3px solid white;
  cursor: pointer;
}

.dream-maker-user-box {
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  width: 78px;
  height: 78px;
}

.dream-maker-main-box {
  width: 220px;
  height: 157px;
  padding: 0px 10px 0px 10px;
  gap: 30px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.dream-makers-name {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: rgba(64, 64, 64, 1);
}

.dream-slider-query {
  width: 860px;
}

.dream-maker-card {
  background: white;
  border-radius: 8px;
  margin-right: 20px;
  padding: 0 0 10px 0;
  height: 277px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px 5px 5px 5px;
}

.missing-data-container {
  width: 320px;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.missing-data-top-content {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.missing-data-content-title {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.97px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #000000;
}

.missing-data-content {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #404040;
}

.missing-data-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: auto;
  width: 173px;
  height: 40px;
  padding: 15px 30px 15px 30px;
  gap: 10px;
  border-radius: 8px;
  border: 1px;
  background: #252525;
  font-family: Space Grotesk;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: white;
}

@media (max-width: 600px) {
  .react-multi-carousel-item.dream-card-mobile {
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  .dream-maker-main-box {
    width: 100%;
    margin-top: 0 !important;
  }
  .dream-maker-card {
    height: 257px !important;
    width: 100%;
    
  }
  .dream-card-mobile-profile{
    margin-left: 6px;
  }
  .dream-maker-main-gap {
    gap: 20px;
  }

  .dream-maker-angel-main-gap {
    gap: 30px !important;
  }
}

@media (min-width: 862px) {
  .dream-maker-card {
    width: 220px;
  }
}

.react-multiple-carousel__arrow {
  z-index: 100 !important;
}

@media (max-width: 866px) {
  .public-dream-photo {
    width: 98% !important;
    height: auto !important;
    aspect-ratio: 1 / 1 !important;
  }
  .carousel-dream-image {
    width: 98% !important;
    height: auto;
  }
}

@media (max-width: 862px) {
  .dream-photo-container {
    width: 96vw;
  }
  .dream-comments-box {
    width: 96vw;
  }
  .dream-slider-query {
    width: 98% !important;
  }
  .nav-back-color {
    padding: 0 10px;
  }
}

@media (max-width: 500px) {
  .dream-photo-container {
    width: 96vw;
  }
  .bymLAd {
    grid-gap: 6px !important;
  }
}

.bqyzQp {
  width: 100%;
}
.hNAxas {
  margin: 0 !important;
  padding-left: 8px;
}

@media (max-width: 400px) {
  .dream-stats-row {
    width: 100%;
    overflow-x: auto;
  }
}