.gradient-button {
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 400;
  cursor: pointer;
  background: linear-gradient(325deg, #f8ed84 0%, #f5e0ff 70%, #84fad5 100%),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box;
  background-origin: border-box !important;
  border: 1px double transparent;
}

.gradient-button:hover {
  font-weight: 700;
  background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
}

.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  color: #a8a6a6;
  cursor: pointer;
}
.share-button:hover {
  background-color: #ebebeb;
  color: black;
}
@media screen and (max-width: 465px) {
  .share-button {
    border: 1px solid #ebebeb;
    width: 100% !important;
  }
  .buttons-container {
    width: 100%;
  }
  .gradient-button {
    width: 100% !important;
  }
}
