$animationSpeed: 40s;

@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(250px * -7));
  }
}

@keyframes scrollLeft {
  100% {
    transform: translateX(0);
  }
  0% {
    transform: translateX(calc(250px * -7));
  }
}

.slider {
  background: transparent;
  height: 60px;
  overflow: hidden;
  position: relative;
  width: 100%;

  .slide-track-to-right {
    animation: scrollRight $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slide-track-to-left {
    animation: scrollLeft $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slide {
    height: 58px;
    width: max-content;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 15px 30px;
    margin: 0 5px;
  }
}
.slider-live-dream {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 440px;
}

.slide-track-to-right-live-dream {
  display: flex;
  flex-direction: column;
  height: calc(100% * 2);
  animation: scroll-vertical-track 30s linear infinite;
  justify-content: center;
  align-items: center;
}

.live-dream-carousel-item {
  display: block;
  margin: 20px 10px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scroll-vertical-track {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 1024px) {
  .slider-live-dream {
    width: 384px;
    min-width: 330px;
  }
}
