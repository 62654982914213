.new-dreams-card-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.new-dreams-card {
  width: 240px;
  height: 340px;
  padding: 10px;
  border-radius: 8px;
  margin: 5px;
  background-color: white !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 10%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.4) 80%
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.new-dream-name-img-box {
  width: 220px;
  height: 40px;
  gap: 10px;
}

.new-dream-user-img {
  width: 40px;
  height: 40px;
  border-radius: 48px;
  object-fit: cover;
}

.new-dream-name-box {
  height: 38px;
}

.new-dream-user-name {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: white;
}

.new-dream-created-time {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #ffffff;
}

.new-dream-details-box {
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.new-dream-title {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: white;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.new-dream-button-gradient {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  backdrop-filter: blur(4px);
  margin-top: 10px;
}
.new-dream-button-gradient:hover {
  background: linear-gradient(
    160deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  font-weight: 500;
  border-radius: 8px;
  backdrop-filter: blur(4px);
}
.new-dream-button-gradient:hover > .new-dream-button-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}

.new-dream-button-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(
    160deg,
    rgb(132, 250, 213) 20%,
    rgb(235, 191, 255) 37%,
    rgb(246, 236, 133) 53%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.new-dream-button-text {
  color: white;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
}

@media (max-width: 768px) {
  .new-dreams-card {
    width: auto !important;
    height: 280px;
  }
  .view-all-birthday-modal .new-dreams-card {
    width: inherit !important;
  }
  .new-dream-name-img-box {
    width: 100%;
  }
}
