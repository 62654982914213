@media screen and (max-width: 750px) {
  .steps-page-container, .create-dream-container {
    padding: 20px 0 60px 0;
  }
  .step-1-container {
    border-radius: 0;
    width: 100%;
    padding: 20px 20px 40px 20px;
  }
  .step-number {
    margin-bottom: 20px;
  }
  .step-5-container {
    padding: 20px 11px 40px 11px;
  }
}

@media screen and (max-width: 390px) {
  .step-1-container {
    padding: 20px 15px 40px 15px;
  }
  .rounded-pictures-white-bg {
    width: 68px;
    height: 68px;
  }
  .calendar-body {
    padding: 0 ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .calendar-dates > div {
    width: 35px;
    height: 35px;
  }
  .calendar-dates {
    display: flex;
    flex-wrap: wrap;
    width: 292px;
    gap: 2px;
    align-items: center;
    justify-content: center;
  }
  .week-day{
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 320px) {
  .step-1-container {
    padding: 20px 10px 40px 10px;
  }
}
@media screen and (max-width: 300px) {
  .rounded-pictures-white-bg {
    width: 55px;
    height: 55px;
  }
}
