.public-dream-card {
  width: 240px;
  height: 322px;
  padding: 0px 0px 30px 0px;
  gap: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 5px 5px 10px 5px;
}

.profile-bg-card {
  width: 240px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 0 0;
}

.public-dream-card-box {
  width: 240px;
  height: 182px;
  padding: 20px 10px 0px 10px;
  gap: 10px;
}

.public-dream-angel-card-box {
  width: 240px;
  height: 182px;
  padding: 20px 10px 0px 10px;
  gap: 34px;
}

.public-dream-user-img-card {
  height: 80px;
  width: 80px;
  line-height: 100px;
  object-fit: cover;
  padding: 2px;
}

.public-dream-card-name-desc-box {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: -webkit-fill-available;
  height: 61px;
  gap: 5px;
}

.public-dream-card-name-angel-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 51px;
  gap: 5px;
}

.public-dream-card-below-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.public-dream-card-angel-fulfill {
  height: 17px;
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: left;
  color: black;
  width: 100%;
}

.public-dream-card-user-name,
.public-dream-card-user-name-angel {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #404040;
  width: fit-content;
  cursor: pointer;
}

.public-dream-card-user-name:hover,
.public-dream-card-user-name-angel:hover {
  text-decoration: underline;
}

.public-dream-card-img-box {
  margin-top: 80px;
}

.public-dream-card-user-dream {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #b6b4b4;
  width: -webkit-fill-available;
  height: 36px;
}

.public-dream-card-fulfilled {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: left;
  color: #000000;
}

.public-dream-card-fulfill-container {
  gap: 10px;
  width: 100%;
}

.public-dream-card-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}

.angel-img-border-gradients {
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  border-radius: 50%;
  padding: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 84px;
  height: 84px;
  margin-top: 80px;
}

@media (max-width: 768px) {
  .public-dream-card {
    width: 96%;
    height: 265px;
    gap: 0;
    padding: 0 0 10px 0;
  }
  .profile-bg-card {
    width: 100%;
    height: 90px;
  }

  .public-dream-card-img-box,
  .angel-img-border-gradients {
    margin-top: 45px;
  }

  .public-dream-card-fulfill-container {
    gap: 4px;
  }

  .public-dream-card-box {
    width: 100%;
    height: 165px;
    padding: 10px 10px 0px 10px;
    gap: 10px;
  }

  .public-dream-user-img-card {
    width: 60px;
    height: 60px;
  }

  .angel-img-border-gradients {
    width: 64px;
    height: 64px;
  }

  .public-dream-angel-card-box {
    height: 165px;
    width: 100%;
    padding: 16px 6px 0px 6px;
    gap: 0px;
    justify-content: space-between;
  }
  .public-dream-card-name-angel-box {
    height: 39px;
    gap: 2px;
  }
  .public-dream-card-user-name-angel {
    font-size: 14px;
    line-height: 17.86px;
  }
  .angel-tag-card {
    margin-top: 0 !important;
  }
  .public-dream-card-below-box {
    height: 67px;
    gap: 10px;
  }
}
