body {
  font-family: "Space Grotesk", serif;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Space Grotesk", serif;
}
.font {
  font-family: "Space Grotesk", serif;
}
.blueBg {
  background-image: url("/public/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 24px;
}
.nav-landing {
  transition: ease-in 300ms;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  height: 72px;
  color: white;
}
.nav-landing > .landing-nav-buttons-container {
  gap: 10px;
}
.nav-landing-white {
  transition: ease-in 300ms;
  transition-timing-function: ease-out;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  position: fixed;
  background: white;
  color: black;
  border-bottom: 1px solid;
  border-image: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  border-image-slice: 1;
}

.custom-gradient-button-space {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 14px;
  backdrop-filter: blur(10px);
  height:  calc(100% - 1px);
  width: calc(100% - 1px);
}

.custom-gradient-button-parent {
  height: 40px;
  width: 139px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-gradient-button-white-parent{
  height: 40px;
  width: 148px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-gradient-button-space::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(
    160deg,
    rgb(132, 250, 213) 20%,
    rgb(235, 191, 255) 37%,
    rgb(246, 236, 133) 53%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
.custom-gradient-button-white {
  background: linear-gradient(white, white),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  border-radius: 8px;
  border: double 1px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: 500;
  font-size: 14px;
  backdrop-filter: blur(0px);
  height: 100%;
  width: 100%;
}
.custom-gradient-button-space:hover,
.custom-gradient-button-white:hover {
  background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
  border-radius: 8px;
  font-weight: bold;
  color: black;
}
.custom-gradient-button-space:hover .custom-gradient-button-space-text {
  color: black;
}
.window-blueBg {
  background-image: url("/src/assets/images/wbackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 24px;
}

.footer-container {
  background-color: #000;
  padding: 30px;
}

.big-double-blueBg {
  background-image: url("/src/assets/images/second.png"),
    url("/src/assets/images/bigSpace.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
}
.main-background-image {
  background: url("/src/assets/images/hero-bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.custom-gradient-button-space-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  text-align: left;
  color: #dcdcdc;
}
.text-color-black {
  color: black;
}
.gradient-sing-up-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.86px;
  text-align: left;
  color: black;
}
.desktop-bg-space {
  padding: 20px 0 64px 0;
  max-width: 1160px;
}
.start-gradient-button-text {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.big-blueBg {
  background-image: url("/src/assets/images/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}

.big-blueBg-desktop {
  background-image: url("/src/assets/images/Background4.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 775px;
  padding: 82px 160px;
}

.secondBg {
  background-image: url("/src/assets/images/SecyrelyDonation.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 710px;
}

.secondBg-desktop {
  background-image: url("/src/assets/images/Background5.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 20px;
}

.logo-icon {
  background-image: url("/public/whiteLogo.svg");
  background-repeat: no-repeat;
  width: 160px;
  height: 60px;
  color: white;
}
.logo-footer {
  background-image: url("/public/whiteLogo.svg");
  background-repeat: no-repeat;
  width: 142px;
  height: 56px;
  color: white;
}

.white-button {
  background-color: white;
  width: 160px;
  height: 48px;
  border-radius: 67px;
}

.color-button {
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  width: 160px;
  height: 48px;
  border-radius: 43px;
}

.color-button:hover {
  font-weight: bold;
}

.desktop-button {
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  width: 350px;
  height: 72px;
  border-radius: 43px;
}

.desktop-button:hover {
  font-weight: bold;
}

.text-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Space Grotesk", serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(38, 38, 38, 1);
}

.text-button:hover {
  font-weight: 600;
}

.text-big-button {
  letter-spacing: 0.14em;
  font-size: 16px;
  color: rgba(38, 38, 38, 1);
}

.title {
  font-size: 34px;
  font-weight: 700;
  color: #333333;
  line-height: 43px;
}

.title-medium {
  font-size: 40px;
  line-height: 50px;
}
.num-text {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: black;
}

.title-small {
  font-size: 19px;
  font-weight: 700;
  color: #202020;
  line-height: 41px;
}

.simple-white-text {
  font-size: 16px;
  font-weight: 700;
  color: white;
  line-height: 22px;
}

.simple-text {
  font-weight: 400;
  font-size: 16px;
  color: #060606;
  line-height: 22px;
}

.white-container {
  padding: 38px;
  height: 350px;
}
.small-white-container {
  padding: 80px 20px;
  gap: 30px;
  background: white;
}

.white-container-num {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1160px;
  padding: 80px 20px;
  z-index: 10;
  max-width: 1160px;
}
.links-container {
  gap: 16px;
}
.gradient-container {
  color: #333333;
  line-height: normal;
  display: flex;
  width: 460px;
  height: max-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(
      325deg,
      rgb(250, 246, 217) 0%,
      rgb(248, 246, 250) 49.54%,
      rgb(227, 246, 241) 100%
    ),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box !important;
  background-origin: border-box !important;
  border: 2px double transparent !important;
  height: 100%;
}
.gradient-container-steps {
  color: #333333;
  line-height: normal;
  display: flex;
  width: 460px;
  height: max-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(
      325deg,
      rgb(250, 246, 217) 0%,
      rgb(248, 246, 250) 49.54%,
      rgb(227, 246, 241) 100%
    ),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box !important;
  background-origin: border-box !important;
  border: 2px double transparent !important;
}
.padding-gradient {
  padding: 40px;
  gap: 20px;
}
.padding-gradient-card {
  height: 100%;
  width: 100%;
  padding: 40px;
}
.gray-bg {
  color: black;
  font-weight: bold;
  font-size: 18px;
  border-radius: 4px;
  background: #ebebeb;
  display: flex;
  padding: 4px 10px;
  align-items: center;
}
.gr-bg {
  color: black;
  font-weight: bold;
  font-size: 18px;
  border-radius: 4px;
  display: flex;
  padding: 4px 10px;
  align-items: center;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.4) 1.02%,
    rgba(235, 191, 255, 0.4) 44.33%,
    rgba(246, 236, 133, 0.4) 76.07%
  );
}

.desktop-white-container {
  padding: 60px 100px;
  height: 413px;
}

.big-container {
  height: 400px;
  padding: 15px;
}

.text-donations {
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #333333;
  text-align: center;
}

.small-text {
  color: #2c2c2c;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
}

.small-text-founder {
  color: #2c2c2c;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
}

.text-uppercase-white {
  text-transform: uppercase;
  color: white;
  font-size: 10px;
  line-height: 13px;
}

.final-text {
  font-size: 28px;
  color: #333333;
  line-height: 36px;
  font-weight: 700;
}

.st-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  text-align: center;
  color: #a8a6a6;
}

.line {
  height: 100%;
  width: 2px;
  background: #f3deaa;
}
.follow-us {
  font-family: Space Grotesk;
  font-weight: 300;
  size: 16px;
  line-height: 20.42px;
  color: #828282;
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.footer-year-text {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.42px;
  text-align: left;
  color: #828282;
}
.second-container {
  background: #f5f5f5;
  padding: 80px 145px;
}
.founder-container {
  background: white;
  padding: 80px 0;
  gap: 30px;
}
.card-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  margin: 0 50px;
  border-radius: 8px;
  background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
  border: 1px solid #ebebeb;
  border-radius: 8px;
  gap: 10px;
}
.image-card {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}

.image-one {
  width: 600px;
}
.width-size {
  width: 550px;
  height: 400px;
}

.test {
  width: 220px !important;
  height: 300px;
}

.carousel-width {
  width: 100%;
}

.carousel-width-public {
  width: 93%;
  position: initial !important;
  /* padding: 0 1337px !important; */
}

.carousel-height-founder {
  width: 200px;
  height: 270px;
  position: unset !important;
}
.right-button {
  right: 25%;
  position: absolute;
  top: 45%;
}
.right-button-dreams {
  right: 0;
  position: absolute;
  top: 45%;
}
.margin-top-mobile {
  margin-top: 5px;
}
.right-button-dream-card,
.left-button-dream-card {
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 62px;
}
.right-button-dream-card:hover,
.left-button-dream-card:hover {
  background-color: #313131;
}

.dot-dream-style > .react-multi-carousel-dot--active > button {
  border-width: 0px;
  border-color: transparent;
  width: 6px;
  height: 6px;
  margin-bottom: 15px;
  background-color: #f1f1f1;
  opacity: 1;
}
.dot-dream-style > li > button {
  background-color: rgb(108, 106, 106, 0.5);
  border-width: 0px;
  border-color: transparent;
  width: 6px;
  height: 6px;
}
.left-button {
  left: 25%;
  position: absolute;
  top: 45%;
}
.left-button-dreams {
  left: 0;
  position: absolute;
  top: 45%;
}
.carousel-height-founder-desktop {
  width: 100%;
}

.display-none-carousel-makers {
  display: none;
}

.footer {
  display: none;
}

.number-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  width: 32px;
  height: 32px;
  color: black;
  background: linear-gradient(325deg, #faf4d0 0%, #fcf9fc 49.54%, #eef6f3 100%),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box !important;
  background-origin: border-box !important;
  border: 1px double transparent !important;
}

.padding-60 {
  padding: 60px;
  gap: 30px;
}
.flex-num {
  width: 600px;
}
.wing-container {
  padding: 80px 20px;
  gap: 40px;
  justify-content: space-between;
  background-color: white;
}
.project-purpose-container {
  padding: 80px 20px;
  gap: 40px;
  justify-content: space-between;
  background-color: #f5f5f5;
}

.social-links-container {
  display: flex;
  flex-direction: column;
}
.secured-title {
  font-family: Space Grotesk;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: center;
  white-space: nowrap;
  margin-top: 20px;
}
.landing-nav-buttons-container > .gradient-button {
  width: 152px;
  height: 40px;
}
.section-container-row-wing {
  gap: 40px;
}
@media screen and (max-width: 1300px) {
  .image-one {
    width: 572px;
  }
  .flex-num {
    width: 500px;
  }
  .desktop-bg-space {
    padding: 34px 80px 64px 80px;
  }
  .main-info {
    width: 607px;
  }
}
@media screen and (max-width: 1100px) {
  .flex-num {
    width: 100%;
  }
  .white-container-num {
    padding: 80px 20px;
  }
  .card-carousel {
    margin: 0 20px;
  }
}
@media screen and (max-width: 820px) {
  .right-button {
    right: 0;
    position: absolute;
    top: 45%;
  }
  .left-button {
    left: 0;
    position: absolute;
    top: 45%;
  }
  .column-card {
    flex-direction: column-reverse !important;
  }
  .project-purpose-container {
    flex-direction: column;
  }
  .secondBg-desktop {
    padding: 60px 10px;
  }
  .section-container-row-secured {
    flex-direction: column-reverse;
  }
  .purpose-image {
    margin-bottom: 20px;
  }
  .section-container-row-wing {
    flex-direction: column;
    gap: 20px;
  }
  .white-container-num {
    padding: 80px 20px;
  }
  .column-mobile {
    flex-direction: column;
    align-items: start !important;
  }
  .resp-center {
    flex-direction: column;
    justify-content: center;
  }
  .second-container {
    padding: 60px 0;
  }

  .social-links-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .links-container {
    gap: 20px;
  }
  .wing-container {
    padding: 60px 10px;
  }
  .project-purpose-container {
    padding: 60px 10px;
  }
}
@media screen and (max-width: 660px) {
  .margin-top-mobile {
    margin-top: 10px;
  }
}
@media screen and (max-width: 660px) {
  .white-container-num {
    padding: 40px 10px;
  }
  .secured-title {
    font-size: 36px;
  }
  .founder-container {
    padding: 60px 0;
  }
}
@media screen and (max-width: 470px) {
  .nav-landing {
    height: 122px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }
  .nav-landing > .landing-nav-buttons-container {
    gap: 4px;
  }
  .line {
    height: 93%;
  }
  .nav-landing-white {
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }
  .custom-gradient-button-parent {
    width: 115px;
  }
  .landing-nav-buttons-container > .gradient-button {
    width: 115px !important;
  }
  .landing-nav-buttons-container > .landing-sign-up-btn {
    min-width: 97px !important;
    width: 97px !important;
  }
  .landing-nav-buttons-container > .custom-gradient-button-white-parent {
    width: 87px !important;
    min-width: 87px !important;
  }
}
@media screen and (max-width: 410px) {
  .title-medium {
    font-size: 30px;
    line-height: 40px;
  }
  .num-text {
    font-size: 16px;
    line-height: 30px;
  }
  .wing-container {
    padding: 60px 10px;
  }
  .project-purpose-container {
    padding: 60px 10px;
  }
  .second-container {
    padding: 60px 0;
  }

  .padding-60 {
    padding: 30px 10px;
  }
  .footer {
    display: unset;
  }
  .secured-title {
    font-size: 30px;
  }

  .window-blueBg {
    flex-direction: column;
  }

  .row-width {
    width: 100% !important;
  }

  .display-none-dream-makers {
    display: none;
  }

  .display-none-carousel-makers {
    display: unset;
  }
  .nav-landing-white {
    border-bottom: 0;
    align-items: center;
    gap: 20px;
    padding: 16px 10px;
  }
  .custom-gradient-button-white {
    background: linear-gradient(white, white),
      linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
    color: white;
  }
  .landing-nav-buttons-container {
    display: block;
  }
  .landing-nav-buttons-container > .custom-gradient-button-white-parent {
    min-width: 87px !important;
    height: 40px;
  }
}
