.messenger-page-container {
  width: 100vw;
  height: calc(100vh - 63px);
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 10px 10px;
}

.messenger-container {
  background-color: white;
  width: 72%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  max-width: 1040px;
}

.chat-list {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right-color: #ebebeb;
  border-right-width: 1px;
  border-right-style: solid;
}

.chat-container {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-open-chat-info {
  width: max-content;
  height: 30px;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.list-header-container {
  width: 100%;
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-color: #ebebeb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 0 10px;
  gap: 10px;
}

.back-btn-container {
  height: 50%;
  width: auto;
  display: flex;
  align-items: center;
}
.back-arrow {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #f5f5f5;
  margin-right: 10px;
  cursor: pointer;
}
.back-arrow:hover {
  color: black;
  background-color: #ebebeb;
}
.back-btn-text {
  font-family: Space Grotesk;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #828282;
  user-select: none;
  white-space: nowrap;
}

.requests-text {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #7ec6ce;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.saved-container {
  width: 100%;
  height: 76px;
  min-height: 76px;
  border-bottom-color: #ebebeb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.saved-container-hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  padding-left: 10px;
  cursor: pointer;
}
.saved-container-hover:hover {
  background-color: #f1f1f1;
}
.saved-icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 48px;
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
  margin-right: 10px;
}
.icon {
  user-select: none;
}
.saved-text {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #252525;
  user-select: none;
  cursor: pointer;
}

.chat-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 136px);
}
.chat-list-padding {
  padding: 20px;
}
.chat-list-container-request-height {
  height: calc(100% - 60px);
}

.no-chats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.no-chats-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.info-title {
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.52px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #404040;
  user-select: none;
  margin-top: 10px;
}
.info-description {
  font-family: Space Grotesk;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.14px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #828282;
  user-select: none;
}

.add-chat-btn-border {
  width: 150px;
  height: 38px;
  border-radius: 16px;
  padding: 1px;
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
}
.add-chat-btn-border:hover {
  padding: 2px;
}
.add-chat-btn {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.add-chat-text {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #252525;
  user-select: none;
}

.search-modal-container {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  max-width: 500px;
  max-height: 506px;
  width: 100%;
  height: max-content;
  gap: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px 0px #0000000f;
}
.search-modal-title {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-modal-title > span {
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
}
.search-modal-input {
  width: 100%;
  height: 32px;
  border: 1px solid #b6b4b4;
  border-radius: 8px;
  padding: 8px 10px;
}
.search-modal-input:hover {
  background: #ebebeb;
  border-color: #6c6a6a;
}
.search-modal-input:focus {
  border-color: #7ec6ce;
}
.search-modal-line {
  width: 100%;
  height: 0px;
  border: 0.5px solid #f1f1f1;
}
.search-modal-btn-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
  user-select: none;
}

.search-modal-results {
  width: 100%;
  height: max-content;
  max-height: 280px;
  overflow-y: scroll;
}

.search-user {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  gap: 10px;
  cursor: pointer;
  background-color: white;
}
.user-chat-info {
  width: 100%;
}
.chat-info-role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected-chat {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  gap: 10px;
  cursor: pointer;
  background: #f1f1f1;
}
.search-user:hover {
  background: #f1f1f1;
}
.search-user-info {
  display: flex;
  flex-direction: column;
}
.gradient-border {
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  border-radius: 50%;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled-btn {
  width: 100%;
  height: 40px;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
  user-select: none;
}
.nothing-saved-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.nothing-saved-circle {
  width: 60px;
  height: 60px;
}
.nothing-saved-icon {
  width: 30px;
  height: 30px;
}
.nothing-saved-description {
  font-family: Space Grotesk;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.14px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #828282;
}
.nothing-saved-title {
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.52px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #404040;
}
.nothing-saved-container-info {
  gap: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.nothing-saved-btn-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  text-align: left;
  color: black;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.nothing-saved-btn-gradient {
  width: 120px;
  height: 40px;
  background: linear-gradient(
    160deg,
    rgb(132, 250, 213) 20%,
    rgb(235, 191, 255) 37%,
    rgb(246, 236, 133) 53%
  );
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
.nothing-saved-btn:hover {
  background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
}
.nothing-saved-btn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
}
.no-message-requests-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 680px;
}
.dream-angel-profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 100%;
}

.dream-angel-profile-name {
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #252525;
  margin-top: 4px;
  cursor: pointer;
}
.dream-angel-profile-angel-badge {
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.31px;
  text-align: left;
  color: black;
}
.dream-angel-profile-info-container {
  gap: 4px;
  margin: 10px;
}
.dream-angel-profile-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 18px;
}
.dream-angel-profile-info-static {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #404040;
}
.dream-angel-profile-info-dynamic {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #404040;
}
.dream-angel-profile-image-gradient {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  border-radius: 50%;
  padding: 2px;
}
.dream-angel-profile-image-no-gradient {
  background: white;
  padding: 0;
}
.dream-angel-profile-image-image {
  width: 80px;
  height: 80px;
  border: 2px solid white;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.dream-angel-profile-btn-container {
  width: 310px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.dream-angel-profile-description {
  font-family: Space Grotesk;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.14px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #828282;
}
.saved-section-container {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.saved-section-header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #f1f1f1;
}
.nothing-saved-section-chat {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}
.saved-section-chat {
  width: 100%;
  height: calc(100% - 60px);
  padding: 0px 20px 10px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
.user-section-chat {
  width: 100%;
  padding: 10px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: auto;
}
.saved-posts-container {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  gap: 4px;
}
.post-time-container {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  gap: 6px;
  width: 100%;
}
.saved-dream {
  width: 372px;
  height: auto;
  max-width: 372px;
  background-color: #f5f5f5;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.saved-dream-pointer {
  cursor: pointer;
}
.saved-dream-info-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.saved-dream-info {
  display: flex;
  flex-direction: column;
  height: 38px;
}
.saved-dream-name {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
  cursor: pointer;
}
.saved-dream-name:hover {
  text-decoration: underline;
}
.saved-dream-time {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
}
.saved-dream-avatar {
  width: 40px;
  height: 40px;
  border-radius: 48px;
  object-fit: cover;
  cursor: pointer;
}
.selected-saved {
  background-color: #ebebeb;
}
.saved-dream-image {
  width: 100%;
  height: 230px;
  border-radius: 8px;
  cursor: pointer;
  object-fit: cover;
}
.saved-dream-description {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #404040;
  white-space: wrap;
}
.saved-dream-progress-bar {
  display: flex;
  flex-direction: column;
  gap: 12.5px;
}
.progress-bar-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.progress-percent {
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.31px;
  text-align: left;
  color: black;
}
.saved-dream-fulfilled-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fulfilled-info {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: left;
  color: #404040;
}
.fulfilled-count {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 700;
  line-height: 16.59px;
  text-align: left;
  color: #404040;
}
.header-info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.saved-header-gradient {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  background: linear-gradient(
    297.06deg,
    #f8ed84 23.88%,
    #f5e0ff 66.2%,
    #84fad5 109.31%
  );
}
.saved-header-icon {
  width: 9px;
  height: 13px;
}
.saved-header-text {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
}
.no-requests-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.saved-close-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.saved-charity-card-container {
  max-width: 372px;
  padding: 10px;
  border-radius: 8px 8px 0 8px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
}
.saved-charity-gradient-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.saved-charity-info {
  display: flex;
  flex-direction: column;
}
.saved-charity-card-title {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
  cursor: pointer;
}
.saved-charity-card-time {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
}

.saved-charity-card-image {
  width: 100%;
  height: 230px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}
.saved-dream-angel-profile-container {
  width: 218px;
  height: 228px;
  background: #f5f5f5;
  padding: 20px 30px;
  border-radius: 8px 8px 0 8px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.saved-dream-angel-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #f1f1f1;
  object-fit: cover;
}
.saved-dream-angel-profile-gradient {
  border-radius: 50%;
  padding: 3px;
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
.saved-dream-angel-profile-container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.saved-dream-angel-profile-name {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
  cursor: pointer;
}
.saved-dream-angel-profile-name:hover {
  text-decoration: underline;
}
.saved-dream-angel-profile-dreams {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
}
.saved-dream-angel-profile-angel-badge {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.chats-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.chats-container-request {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.chats-section {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  overflow: scroll;
  height: calc(100% - 60px);
}
.chats-section-request {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  overflow: scroll;
  height: calc(100% - 60px);
}
.add-chats-container {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-card-container {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-card-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-width-height {
  height: 36px;
  width: 36px;
}
.chat-card-image {
  width: 100%;
  height: 100%;
}
.chat-card-text-container {
  height: 38px;
  display: flex;
  flex-direction: column;
}
.chat-card-name {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
}
.chat-card-time {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
}
.chat-card-angel-text {
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.31px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.tooltip-angel-badge-chat-card {
  width: 76px;
  height: 19px;
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 4px;
  background: linear-gradient(
    160deg,
    rgba(132, 250, 213, 0.4) 1.02%,
    rgba(235, 191, 255, 0.4) 44.33%,
    rgba(246, 236, 133, 0.4) 76.07%
  );

  display: flex;
  justify-content: center;
}
.time-difference-container {
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seen-time-container {
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.time-difference-text {
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #b6b4b4;
}
.chat-header-image-dream-angel {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.chat-header-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.chat-input-container {
  min-height: 72px;
  max-height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;
  border-top: 1px solid #ebebeb;
}
.chat-input-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5 !important;
  min-height: 52px;
  width: 100%;
  border-radius: 10px;
  padding: 6px 6px 6px 10px;
  gap: 6px;
}
.chat-input-form:focus {
  border: 1px solid #a8a6a6 !important;
  color: black !important;
}
.chat-input {
  background-color: #f5f5f5 !important;
  min-height: 20px;
  max-height: 112px;
  width: 100%;

  resize: none;
  border: none;

  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #252525;
}
.chat-input::-webkit-scrollbar {
  width: 1px;
  display: block !important;
}
.chat-input::-webkit-scrollbar-track {
  background: #f5f5f5 !important;
}
.chat-input::-webkit-scrollbar-thumb {
  background: #b4b5b6 !important;
  border-radius: 10px !important;
}
.chat-input::-webkit-scrollbar-thumb:hover {
  background:#b4b5b6;
}

.chat-send-icon-container:hover {
  border: 1.5px solid #7ec6ce;
}
.chat-send-icon-container {
  max-width: 0px;
  /* max-height: 0px; */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #b0e8e8;
  transition: 0.5s ease;
}
.see-chat-icon {
  /* max-height: 40px; */
  /* height: 40px; */
  max-width: 48px;
  width: 48px;
}
.no-see-chat-icon {
  /* height: 40px; */
  /* max-height: 0px; */
  max-width: 0px;
  width: 48px;
}
.user-chat-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.user-message-container {
  width: 100%;
  display: flex;
  gap: 12px;
}
.my-message-container {
  flex-direction: row-reverse;
}
.my-message {
  background: #f5f5f5;
}
.not-my-message {
  background: white;
  border: 1px solid #ebebeb;
}
.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  max-width: 500px;
}
.gradient-border-message {
  width: 30px;
  height: 30px;
}
.message-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #333333;
}
.message-text-right {
  text-align: right;
}
.last-message {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.last-message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.last-message-time {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #828282;
  white-space: nowrap;
}
.last-message-time-point {
  margin-right: 4px;
}
.last-message-time-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.user-name-chat {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
}
.chat-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 120px);
}
.no-open-chat-text {
  font-family: Space Grotesk;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #b6b4b4;
}
.dream-angel-gradient-btn {
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 400;
  cursor: pointer;
  background: linear-gradient(325deg, #f8ed84 0%, #f5e0ff 70%, #84fad5 100%),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box;
  background-origin: border-box !important;
  border: 1px double transparent;
}
.dream-angel-gradient-btn:hover {
  font-weight: 700;
  background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
}
.dream-angel-gradient-btn-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.send-user-container {
  justify-content: space-between;
}
.send-user-info-container {
  display: flex;
  flex-direction: column;
}
.send-user-card {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.send-user-name-role {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.send-user-name-chat {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404040;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.send-angel-margin {
  margin: 0;
}
.send-user-checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid #ebebeb;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.send-user-checked {
  background: #252525;
  border: 0;
}
.modal {
  overflow-y: unset !important;
  overflow-x: unset !important;
}

@media screen and (max-width: 1400px) {
  .messenger-container {
    width: 95%;
  }
}
