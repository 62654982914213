.dream-app {
  margin-top: 30px;
  font-family: Arial, sans-serif;
}

.birthday-app {
  margin-top: 30px;
}

.dream-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
p {
  margin: 0;
  padding: 0;
}

.dream-dreams-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 63vw;
  scroll-behavior: smooth;
}
.dream-card {
  background: white;
  border-radius: 8px;
  margin-right: 20px;
  padding: 10px;
  height: 354px;
  width: 240px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px 5px 5px 5px;
}

.dream-card-main-container {
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 138px;
  gap: 10px;
}

.dream-card-header {
  display: flex;
  align-items: center;
  height: 36px;
  width: 220px;
  justify-content: flex-start;
}

.dream-avatar {
  border-radius: 50%;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.dream-card-name {
  color: #404040;
  text-decoration: none;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.86px;
  letter-spacing: -0.02em;
  text-align: left;
}

.dream-card-time-ago {
  color: #b6b4b4;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
}

.dream-image {
  width: 100%;
  border-radius: 8px;
  height: 140px;
  object-fit: cover;
}
.dream-carousel-images {
  width: 100%;
  height: 100%;
}
.dream-image-carousel {
  width: 100%;
  border-radius: 8px;
  height: 140px;
  object-fit: cover;
}
.sliders-button {
  width: 100%;
  height: 40px;
}

.dream-description {
  height: 36px;
  letter-spacing: -0.04em;
  color: #404040;
}

.dream-progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.dream-progress-bar {
  background: yellow;
  height: 5px;
  flex-grow: 1;
  margin-right: 5px;
}

.dream-percentage {
  font-size: 12px;
  color: #000000;
  font-weight: 700;
  line-height: 15.31px;
}

.dream-fulfillment-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 17px;
}

.dream-fulfillment-container {
  display: flex;
  gap: 4px;
}

.dream-fulfillment-info p {
  color: #404040;
  font-size: 13px;
  line-height: 16.59px;
}

.dream-fulfillment-info p:nth-of-type(2) {
  font-weight: 700;
}

.dream-fulfill-button {
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
}

.dream-name-and-date {
  font-weight: 800;
  color: #444444;
}

.view-all-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.view-all-popup.show {
  display: flex;
}

.view-all-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px #2224290d;
  border-radius: 5px 5px 0 0;
  z-index: 100;
}

.items-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 35px 53px;
  border-radius: 0 0 5px 5px;
  background-color: #ebebeb;
  max-height: 80vh;
}

.view-all-button {
  padding: 4px 2px 0;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.dream-slider-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.97px;
  letter-spacing: -0.02em;
  color: black;
}

.view-all-button:hover {
  border-bottom: 1px solid #333333;
  color: #333333 !important;
}

.view-all-button:hover p {
  color: #333333 !important;
}

.view-all-button img {
  width: 16px;
  transition: all 0.3s ease;
}
.view-all-button-container {
  display: flex;
  align-items: center;
}
.view-all {
  color: #828282;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  text-align: left;
}
.carousel-arrow-container {
  display: flex;
  gap: 20px;
}
.icon-container-hover-right,
.icon-container-disabled-right {
  display: flex;
  justify-content: end;
  align-items: center;
}
.icon-container-hover-right:hover {
  padding-right: 4px;
  transition: all 0.2s linear;
}
.icon-container-hover-left:hover {
  padding-left: 4px;
  transition: all 0.2s linear;
}

@keyframes hoverColor {
  from {
  }

  to {
    fill: black;
  }
}
.icon-container-hover-right:hover > .img-arrow-hover > path,
.icon-container-hover-left:hover > .img-arrow-hover > path {
  animation: hoverColor 0.2s both;
}
.icon-container-hover-right,
.icon-container-hover-left {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  fill: #828282;
}

.icon-container-disabled-right,
.icon-container-disabled-left {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
}

.arrow-sliders-disabled {
  fill: #B6B4B4;
}

.items-container .dream-card {
  flex: 1 0 240px;
  max-width: calc((100% - 3 * 1rem) / 4);
}

@media (max-width: 1200px) {
  .items-container .dream-card {
    max-width: calc((100% - 2 * 1rem) / 3);
  }
  .modal-dialog.modal-xlg {
    max-width: 900px;
  }
}

@media (max-width: 900px) {
  .items-container .dream-card {
    max-width: calc((100% - 1 * 1rem) / 2);
  }
  .modal-dialog.modal-xlg {
    max-width: 700px;
  }
}

@media (min-width: 601px) and (max-width: 770px) {
  .items-container .dream-card {
    max-width: calc((100% - 1 * 1rem) / 2);
  }
  .items-container .dream-card {
    max-width: 240px !important;
  }
  .dream-card {
    width: initial;
  }
  .dream-dreams-container {
    width: 95vw;
  }
  .items-container {
    padding: 10px 0 0 0;
  }
}

@media (max-width: 600px) {
  .items-container {
    padding: 10px 0 0 0;
  }
  .items-container .dream-card {
    max-width: 180px;
  }
  .view-all-header {
    justify-content: space-between;
  }
  .dream-dreams-container {
    margin: 0 3px;
    width: 100vw;
  }
  .modal-dialog.modal-xlg {
    max-width: 100%;
    margin: 0;
  }
  .view-all-button {
    margin-right: 5px;
  }
  .view-all-button:hover {
    border: none;
  }
}

@media (min-width: 1200px) {
  .modal-dialog.modal-xlg {
    max-width: 1160px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .dream-card-header {
    width: 100%;
  }
  .dream-card {
    height: 334px;
    width: initial;
  }
  .dream-slider-title {
    padding-left: 5px;
    color: #404040;
  }
  .dream-image {
    height: 120px;
  }
}

.modal-dialog.modal-xlg {
  margin-right: auto;
  margin-left: auto;
}

.dashboard-card-buttons-text {
  letter-spacing: -0.04em;
}

@media (max-width: 450px) {
  .slider-margin-mobile {
    margin-left: 2.5px;
  }
}
