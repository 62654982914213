.tooltip-details-container {
  background-color: white !important;
  border-radius: 8px !important;
  text-align: center;
  opacity: 1 !important;
  transition: opacity 0.3s;
  width: 340px;
  z-index: 100;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 8px 0px #00000029;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  gap: 20px;
  font-family: Space Grotesk;
}

.tooltip-arrow {
  display: none;
}

.tooltip-user-details-box {
  width: 300px;
}

.tooltip-user-details {
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.tooltip-gap-4 {
  gap: 4px;
}

.tooltip-user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.tooltip-name {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.97px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black;
}

.tooltip-name:hover {
  text-decoration: underline;
}

.tooltip-details {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #404040;
}

.tooltip-600 {
  font-weight: 600;
}

.tooltip-user-dream {
  max-height: 54px;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #b6b4b4;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-user-buttons-box {
  width: 300px;
  gap: 4px;
  display: flex;
}

.tooltip-angel-img-box {
  display: flex;
  gap: 10px;
}

.tooltip-angel-badge {
  width: 87px;
  height: 22px;
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 4px;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.4) 1.02%,
    rgba(235, 191, 255, 0.4) 44.33%,
    rgba(246, 236, 133, 0.4) 76.07%
  );
  color: black;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  display: flex;
  justify-content: center;
}
.comment-badge {
  width: 76px;
  height: 16px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.31px;
  text-align: left;
  margin-left: 5px;
}

.tooltip-angel-img {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
}

.tooltip-angel-img-border {
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  padding: 2px;
  position: relative;
}

.tooltip-angel-button-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.tooltip-message-btn {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}
