.card-border-rainbow {
  background: linear-gradient(white, white),
    linear-gradient(114.93deg, #84fad5 1.02%, #ebbfff 44.33%, #f6ec85 76.07%);
  border-radius: 8px;
  border: double 2px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  cursor: pointer;
  margin: 5px;
  width: 220px;
  height: 276px;
}

.donors-card-img-box {
  width: 100%;
  height: 100px;
  padding-top: 22px;
  width: 220px;
  gap: 10px;
}

.donors-card-img {
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  width: 78px;
  height: 78px;
  margin: auto;
}

.angel-donors-card-img {
  background: linear-gradient(white, white),
    linear-gradient(114.93deg, #84fad5 1.02%, #ebbfff 44.33%, #f6ec85 76.07%);
  border-radius: 8px;
  border: double 2px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  width: 82px;
  height: 82px;
  margin: auto;
}

.donors-card-main-box {
  width: 100%;
  height: 152px;
  padding: 0px 10px;
  gap: 20px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}

.donors-card-name-dscription {
  width: 100%;
  height: 66px;
  gap: 4px;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.donors-card-name {
  width: fit-content;
  padding: 4px;
  gap: 10px;
  border-radius: 4px;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.2) 1.02%,
    rgba(235, 191, 255, 0.2) 44.33%,
    rgba(246, 236, 133, 0.2) 76.07%
  );
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}

.donors-card-dream-description {
  height: 36px;
  font-family: Space Grotesk;
  letter-spacing: -0.04em;
  text-align: center;
  color: #b6b4b4;
}

.donors-card-fulfill-box {
  width: 100%;
  height: 66px;
  gap: 10px;
}

.donors-card-dream-angel-status {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: center;
  background: linear-gradient(
    94.02deg,
    #6be4be 25.92%,
    #d69cf1 49.03%,
    #e3dda2 69.12%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .card-border-rainbow {
    width: auto !important;
    height: 250px !important;
  }
  .view-all-birthday-modal .card-border-rainbow {
    width: inherit !important;
  }
  .donors-card-img-box {
    height: 90px;
    width: 100%;
    padding-top: 12px;
  }
  .donors-card-main-box {
    height: 146px;
    gap: 10px;
  }
  .donors-card-img {
    width: 70px;
    height: 70px;
  }
  .angel-donors-card-img {
    width: 74px;
    height: 74px;
  }
  .donors-card-fulfill-box {
    gap: 4px;
    height: 60px;
  }
}
