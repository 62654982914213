
.card-responsive.react-multi-carousel-item {
    opacity: 0.4;
}
.card-responsive.react-multi-carousel-item--active {
   opacity: 1;
}
@media screen and (max-width: 500px) {
    .card-mobile.react-multi-carousel-item--active {
        width: 250px !important;
        margin-right: -25px;
        margin-left: -25px;
    }

}