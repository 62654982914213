.wing-donation-page-card {
  width: 680px;
  margin: auto;
}

.wing-page-newsfeed-box {
  margin-top: -9px;
}

.angel-tag-card {
  background: linear-gradient(
    114.93deg,
    #84fad566 1.02%,
    #ebbfff66 44.33%,
    #f6ec8566 76.07%
  );
  border-radius: 4px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  letter-spacing: -0.04em;
  color: black;
  margin-top: 7px;
}

.go-back-button {
  margin: 10px 0 20px 0;
}
.back-button-container:hover {
  background-color: #ebebeb;
}
.back-button-container:hover > .back-button-text {
  color: #333333;
}
.back-button-container:hover > .back-button-icon > path {
  stroke: #333333;
}
.back-button-container {
  width: 64px;
  height: 28px;
  border-radius: 40px;
  padding: 6px;
  gap: 4px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.back-button-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #828282;
}

@media (max-width: 682px) {
  .wing-donation-page-card {
    width: 98% !important;
    margin: auto;
  }
  .go-back-button {
    margin: 0 10px 10px 10px;
  }
}
