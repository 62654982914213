.birthday-card {
  background: white;
  border-radius: 8px;
  margin-right: 20px;
  padding: 0 0 10px 0;
  height: 313px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px 5px 5px 5px;
}

.birthday-card-header {
  width: 220px;
  height: 110px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.birthday-avatar {
  border-radius: 50%;
  width: 82px;
  height: 82px;
  object-fit: cover;
  border: 3px solid white;
  cursor: pointer;
}

.birthday-angel-avatar-border {
  position: relative;
  border-radius: 50%;
  padding: 2px;
  background: linear-gradient(
    114.93deg,
    #84fad5 1.02%,
    #ebbfff 44.33%,
    #f6ec85 76.07%
  );
  margin-top: -75px;
}

.birthday-angel-avatar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  width: 82px;
  height: 82px;
  padding: 3px;
}

.profile-img-border {
  border-radius: 50%;
  border: 4px solid white;
  cursor: pointer;
}

.border-radius-img {
  border-radius: 50%;
}

.scope-container {
  width: 100%;
  height: 17px;
}

.progress-box {
  flex-direction: column;
  display: flex;
  gap: 10px;
  height: 41px;
  width: 100%;
}

.birthday-main-box {
  width: 220px;
  height: 183px;
  padding: 10px 10px 0px 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: space-between;
}

.birthday-user-container {
  width: 200px;
  height: 26px;
  gap: 5px;
  display: flex;
  justify-content: center;
}

.birthday-user-box {
  height: 26px;
  padding: 4px;
  gap: 5px;
  border-radius: 4px;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.4) 1.02%,
    rgba(235, 191, 255, 0.4) 44.33%,
    rgba(246, 236, 133, 0.4) 76.07%
  );
  display: flex;
  justify-content: space-between;
}

.birthday-card-name {
  font-weight: 700;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  color: #000000;
}

.birthday-emoji {
  line-height: 17.86px;
  font-size: 13px;
}

.birthday-dream-box {
  width: 200px;
  height: 36px;
}

.birthday-dream-box span {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #b6b4b4;
}

.birthday-name-and-dream {
  height: 68px;
  padding: 10px 0px 10px 0px;
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.dream-scope {
  font-size: 13px;
  font-weight: 400;
  line-height: 16.59px;
  color: rgba(0, 0, 0, 1);
}

.birthday-dream-percentage {
  font-size: 13px;
  font-weight: 700;
  line-height: 16.59px;
  color: rgba(0, 0, 0, 1);
}

.birthday-img-mg {
  margin-top: -70px;
}

@media (max-width: 768px) {
  .birthday-avatar {
    border: 2px solid white !important;
  }
  .birthday-angel-avatar {
    padding: 3px !important;
    width: 60px;
    height: 60px;
  }
  .birthday-card {
    width: -webkit-fill-available;
    height: 275px;
  }
  .birthday-card-header {
    width: 100%;
    height: 90px;
  }
  .birthday-avatar {
    width: 60px;
    height: 60px;
  }
  .birthday-angel-avatar-border {
    margin-top: -57px;
  }
  .birthday-img-mg {
    margin-top: -53px;
  }
  .birthday-main-box {
    width: 100%;
    height: 175px;
    margin-top: 0;
  }
  .birthday-user-container {
    width: 100%;
  }
  .progress-box {
    gap: 4px;
    height: 35px;
  }
  .birthday-dream-box {
    margin-top: -6px;
  }
  .my-name-span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.birthday-app .react-multi-carousel-item.dream-card-mobile {
  padding: 0;
}

@media (max-width: 600px) {
  .react-multi-carousel-item.dream-card-mobile {
    padding: 5px;
  }
}

@media (max-width: 768px) {
  .view-all-birthday-modal {
    width: 179px !important;
  }
}
