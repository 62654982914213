
@import "./custom/helper/fonts";

// Configuration
@import "../../../node_modules/bootstrap/scss/functions";
// Variables
@import "./custom/variables";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";

@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";

// Custom Mixin And Helper
@import "./custom/helper/functions";
@import "./custom/helper/mixins";

// Layout & components
@import "./custom/helper/root";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/spinners";
@import "../../../node_modules/bootstrap/scss/offcanvas";

// Helpers
@import "../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities/api";

// // Extra Components and Utilities and plugins
@import "./custom/components/components";
@import "./custom/helper/utilities";
@import "./custom/plugins/plugins";

@import "./custom/mode/dark/index";
@import "./custom/mode/rtl/index";
@import '../../../node_modules/remixicon/fonts/remixicon.css';
@import '../../../node_modules/line-awesome/dist/line-awesome/css/line-awesome.min.css';
@import '../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';