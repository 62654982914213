.activity-page-container {
  max-width: 860px;
  align-items: center;
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.activity-page-menu-box {
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 174px;
  padding: 10px;
  gap: 6px;
  border-radius: 8px;
  background-color: white;
  position: relative;
  margin-bottom: auto;
  margin-top: 20px;
}

.activity-page-menu-active-item {
  width: 150px;
  height: 34px;
  padding: 8px 20px 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  background: #f5f5f5;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.86px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black;
}

.activity-page-menu-item {
  cursor: pointer;
  width: 150px;
  height: 34px;
  padding: 8px 20px 8px 20px;
  border-radius: 8px;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 00;
  line-height: 17.86px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black;
}

.activity-commented-span {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: rgb(41, 41, 41);
}

.activity-content {
  width: 680px;
  gap: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.text-underline:hover {
  text-decoration: underline;
}

@media (max-width: 880px) {
  .activity-page-container {
    width: inherit !important;
  }
  .activity-content {
    width: inherit !important;
  }
}

@media (max-width: 650px) {
  .activity-page-container {
    display: block !important;
  }
  .activity-page-menu-box {
    width: 94vw !important;
    margin: 20px !important;
  }
  .activity-page-menu-active-item {
    width: 100% !important;
  }
  .activity-page-menu-item {
    width: 100% !important;
  }
}

@media (max-width: 670px) {
  .activity-type-content-name {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 0 !important;
  }
}
