@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

.header-network {
  font-family: "Caveat", cursive;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;
  background: linear-gradient(
    94.02deg,
    #6be4be 25.92%,
    #d69cf1 49.03%,
    #e3dda2 69.12%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.start-gradient-button {
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 700;
  cursor: pointer;
  background: linear-gradient(325deg, #f8ed84 0%, #f5e0ff 70%, #84fad5 100%),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box;
  background-origin: border-box !important;
  border: 1px double transparent;
  transition: all 2s ease-in-out;
}
.start-gradient-button-project-purpose {
  height: 60px;
  width: 192px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 700;
  cursor: pointer;
  background: linear-gradient(325deg, #f8ed84 0%, #f5e0ff 70%, #84fad5 100%),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box;
  background-origin: border-box !important;
  border: 1px double transparent;
  transition: all 2s ease-in-out;
}
@keyframes gradientBtnHover {
  0% {
    background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
    opacity: 0.8;
  }
  25% {
    background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
    opacity: 0.85;
  }
  50% {
    background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
    opacity: 0.9;
  }

  100% {
    background: linear-gradient(325deg, #f6ec85 0%, #ebbfff 70%, #84fad5 100%);
    opacity: 1;
  }
}
.start-gradient-button:hover {
  font-weight: 700;
  animation: gradientBtnHover 1s;
}
.carousel-section-item {
  width: 253px !important;
  margin: 0 10px;
}
.dreamerz-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 80px 20px;
  z-index: 1;
  background-color: white;
}
.section-container-video {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1160px;
}
.section-container-dreamerz-video {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1160px;
  width: 100%;
}

.section-container-carousel-landing {
  padding: 80px 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1160px;
}

.section-container-row {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1160px;
}
.section-container-live-dream {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1160px;
  width: 100%;
  z-index: 2;
  gap: 40px;
  height: 410px;
}
.dreamerz-video {
  margin-top: 20px;
  width: 100%;
  max-width: 960px;
  aspect-ratio: 16 /9;
  z-index: 10;
}
.video-title {
  color: black;
  font-family: Space Grotesk;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: center;
}

.steps-container {
  background-color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: white;
  position: relative;
  z-index: 10;
}
.dreams-title {
  font-family: "Space Grotesk", serif;
  color: black;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -4%;
  line-height: 20px;
  text-align: center;
}
.dream-angel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.dream-angel-title {
  font-family: "Space Grotesk", serif;
  color: black;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -4%;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
}
.card-title {
  font-family: "Space Grotesk", serif;
  color: black;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -4%;
  line-height: 24px;
  text-align: center;
}
.dream-angel-saying {
  font-family: Caveat;
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #7ec6ce;
  transform: rotate(-5deg);
}

.secured-card {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.secured-image {
  width: 560px;
}
.main-title-description {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #b6b4b4;
}
.secure-text {
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #a8a6a6;
  margin-top: 10px;
}
.check-list {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
}
.list-text {
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: left;
  color: whitesmoke;
}
.margin-b {
  margin-bottom: 10px;
}
.list-element {
  display: flex;
  align-items: end;
  gap: 10px;
}
.list-element > img {
  align-self: flex-start;
}
.secured-button-text {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}

.wing-title-section {
  font-family: Space Grotesk;
  font-size: 32px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #333333;
}
.wing-text {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #a8a6a6;
}

.purpose-button-text {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.main-container {
  width: 100%;
  gap: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  z-index: 1;
  padding: 80px 0px;
}

.info-container {
  width: 1160px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.info-container > h3 {
  font-family: Space Grotesk;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #252525;
  letter-spacing: -0.04em;
}
.info-container > span {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #828282;
}
.carousel-container-section {
  width: 100%;
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.carousel-card {
  height: 58px;
  width: max-content;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 15px 30px;
  margin: 5px;
}
.carousel-card-text {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: black;
  white-space: nowrap;
}
.first-carousel {
  width: 100%;
  height: 58px;
}

.questions-section-container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 80px 20px;
}
.questions-text-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question-title {
  font-family: Space Grotesk;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black;
}

.question-description {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #000000;
}
.questions-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.section-container,
.section-container-accordion {
  width: 100%;
  gap: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1160px;
}
.question-card {
  width: 660px;
}
.accourdion-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 72px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(233, 233, 233, 0.1) 100%
  );
  border: 1px solid #ebebeb;
  border-radius: 8px;
  cursor: pointer;
}
.accourdion-container:hover {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(233, 233, 233, 0.5) 100%
  );
}
.accourdion-text-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 80%;
}
.accourdion-title {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #252525;
}
.accourdion-description {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #828282;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease;
}
.see-description {
  margin-top: 10px;
  max-height: 200px;
}
.accourdion-btn {
  width: 40px;
  height: 40px;
  gap: 6.8px;
  border-radius: 13.6px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  align-self: flex-start;
  margin-top: 16px;
  transition: all 1s;
}

.accourdion-text-container {
  margin-left: 20px;
}

.live-dream-section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0 20px;
}

.blurred-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
      90deg,
      #6be4be24 10%,
      #d69cf124 50%,
      #e3dda224 90%
    ),
    linear-gradient(#fff, #fff);
  z-index: 1;
}
.live-dream-text-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 660px;
  width: 100%;
}
.live-dream-title {
  font-family: Caveat;
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  color: #000000;
}
.live-dream-description {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #6c6a6a;
}
.live-dream-btn {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 400;
  color: white;
  line-height: 24px;
  text-align: left;
  border-radius: 8px;
  background-color: #000000;
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding: 12px 24px;
}
.live-dream-btn:hover {
  display: block;
  border: 1px solid #333333;
  background-color: #333333;
}

.live-dream-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36%;
  height: 100%;
  transform: rotate(-90deg);
  max-width: 410px;
}
.d-carousel {
  height: 100%;
  width: 100%;
}
.d-carousel > ul {
  width: 20px;
  height: 20px;
}
.live-dream-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(325deg, #f8ed84 0%, #f5e0ff 70%, #84fad5 100%),
    linear-gradient(160deg, #84fad5 20%, #ebbfff 37%, #f6ec85 53%);
  background-clip: content-box, border-box;
  background-origin: border-box !important;
  width: max-content;
  height: max-content;
  border-radius: 6px;
}
.live-dream-carousel-item > span {
  font-family: Space Grotesk;
  letter-spacing: -0.04em;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  text-align: left;
  color: #404040;
  margin: 5px;
  white-space: nowrap;
  align-self: center;
}
.d-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  transform: rotate(90deg);
}
.container-for-svg {
  width: 100%;
  position: relative;
}

.landing-page-svg {
  position: absolute;
  padding-top: 150px;
  align-self: flex-end;
  z-index: 1;
}

.main-info {
  align-items: start;
  width: 660px;
  max-width: 660px;
  gap: 20px;
}
.window-text-steps {
  font-family: Space Grotesk;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  color: #000000;
  letter-spacing: -0.04em;
}
.wing-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.purpose-info {
  align-items: start;
  gap: 20px;
}
.purpose-carousel-item {
  background-color: transparent;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto !important;
}
.main-title-title {
  font-family: Space Grotesk;
  font-size: 44px;
  font-weight: 700;
  line-height: 52.8px;
  letter-spacing: -0.04em;
  text-align: left;
  color: white;
}
.carousel-section-title {
  font-family: Space Grotesk;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  color: black;
}
.landing-page-container {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.secure-btn {
  width: 263px;
}
.latest-dreams-carousel-item {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  width: max-content;
}
.wing-image {
  width: 560px;
}
.st-description {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.42px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #a8a6a6;
}
.gradient-container {
  width: 100%;
  height: 180px;
}
.number-container {
  width: 100%;
}
.team-section-user-name {
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #333333;
}
.team-section-user-function {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: black;
}
.team-section-info-container {
  gap: 10px;
}
.margin-l {
  margin-left: 20px;
}
.margin-r {
  margin-right: 20px;
}
.main-btn-container {
  margin-top: 20px;
}
.landing-nav-buttons-container {
  gap: 4px;
}
.secured-container-gap {
  gap: 40px;
}
@media screen and (max-width: 1180px) {
  .section-container-carousel-landing {
    width: 96%;
  }
  .main-info {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .section-container-live-dream {
    height: 530px;
  }
  .main-info {
    width: 607px;
  }
  .desktop-bg-space {
    flex-direction: column-reverse;
    padding: 34px 21px 78px 21px;
  }
  .image-one {
    width: 80%;
  }
  .secure-btn {
    width: 285px;
  }
  .start-gradient-button-project-purpose {
    width: 201px;
  }
  .purpose-button-text {
    font-size: 18px;
  }
  .secured-button-text {
    font-size: 18px;
  }
  .card-carousel {
    padding: 16px 20px 24px 20px;
  }
  .wing-info {
    gap: 10px;
  }
  .main-container {
    padding: 60px 0px;
  }
  .secured-image {
    width: 472px;
  }
  .question-card {
    width: 500px;
  }
  .carousel-card {
    height: 58px;
    width: auto;
  }
  .landing-page-svg {
    padding-top: 180px;
  }
  .live-dream-carousel-container {
    width: 42%;
  }
  .d-carousel {
    height: 100%;
    width: 100%;
  }
  .live-dream-text-container {
    margin: 60px 0;
    max-width: 546px;
  }
  .main-title-description {
    line-height: 26px;
  }
  .main-title-title {
    font-size: 42px;
    line-height: 50.4px;
  }
  .white-container-num {
    flex-wrap: wrap;
    gap: 40px;
  }
  .gradient-container-steps {
    width: 100%;
  }
  .padding-gradient {
    padding: 20px;
  }
}

@media screen and (max-width: 900px) {
  .secured-image {
    width: 420px;
  }
  .section-container,
  .section-container-accordion {
    flex-wrap: wrap;
    padding-right: 10px;
    padding-left: 10px;
  }
  .questions-container {
    width: 100%;
  }
  .question-card {
    width: 100%;
  }
}
@media screen and (max-width: 820px) {
  .wing-info {
    align-items: center;
  }
  .wing-text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .purpose-info {
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 760px) {
  .info-container {
    width: 100%;
    padding: 0 10px;
  }
  .dream-angel-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .padding-gradient-card {
    padding: 20px;
  }
  .gradient-container {
    height: 122px;
  }
}

@media screen and (max-width: 660px) {
  .desktop-bg-space {
    padding: 0 20px;
    padding-bottom: 60px;
    gap: 29px;
  }
  .start-gradient-button-project-purpose {
    width: 188px;
    height: 50px;
  }
  .secured-container-gap {
    gap: 20px;
  }
  .purpose-button-text {
    font-size: 14px;
  }
  .main-background-image {
    gap: 29px;
  }
  .secured-image {
    width: 393px;
    height: 415px;
  }
  .info-container > h3 {
    font-size: 30px;
    line-height: 36px;
  }
  .carousel-card-text {
    font-size: 16px;
    line-height: 16px;
  }
  .st-description {
    font-size: 14px;
    line-height: 17.86px;
  }
  .dreamerz-video-container {
    padding: 60px 20px;
  }
  .section-container-video {
    width: 100%;
  }
  .main-info {
    align-items: center;
    width: 95%;
    gap: 10px;
  }
  .carousel-section-title {
    font-size: 30px;
    font-weight: 400;
    color: #333333;
  }

  .landing-page-svg {
    padding-top: 150px;
    width: 550px;
  }
  .section-container-live-dream {
    flex-direction: column;
    gap: 54px;
    padding: 60px 0;
    height: max-content;
  }
  .live-dream-btn {
    margin-top: 10px;
  }
  .live-dream-section {
    padding: 0;
  }
  .live-dream-text-container {
    margin: 0;
    padding: 0 20px;
    max-width: 100%;
    gap: 20px;
  }

  .live-dream-carousel-container {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
    max-width: 660px;
  }
  .d-carousel-item {
    width: auto !important;
    transform: rotate(0deg);
  }

  .d-carousel {
    width: 100%;
  }
  .carousel-width-public {
    width: 105% !important;
  }
  .main-title-description {
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    overflow-wrap: break-word;
    width: 100%;
    color: #a8a6a6;
  }
  .main-title-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .main-title-title {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }
  .question-title {
    font-size: 26px;
    line-height: 28px;
  }
  .question-description {
    font-size: 16px;
    line-height: 24px;
  }
  .window-text-steps {
    font-size: 36px;
  }
  .questions-section-container {
    padding: 50px 10px;
  }
  .wing-title-section {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .wing-image {
    width: 400px;
  }
  .small-white-container {
    padding: 60px 10px;
  }
  .dream-angel-title {
    font-size: 30px;
    line-height: 40px;
  }
  .gradient-container {
    height: 142px;
  }
  .questions-text-container {
    gap: 10px;
  }
  .section-container-accordion {
    padding: 0 !important;
    gap: 30px;
  }
}
@media screen and (max-width: 460px) {
  .video-title {
    font-size: 30px;
    line-height: 36px;
  }
  .gradient-container {
    height: 162px;
  }
  .secured-image {
    width: 100%;
  }
  .secured-card {
    width: 100%;
  }
}
@media screen and (max-width: 414px) {
  .live-dream-btn {
    margin-top: 20px;
  }
  .main-info {
    margin-left: 0;
  }
  .desktop-bg-space {
    gap: 10px;
    padding: 0 10px;
    padding-bottom: 40px;
  }
  .main-background-image {
    gap: 10px;
  }
  .nav-landing {
    padding: 20px 10px 10px 10px;
  }
  .main-title-title {
    font-size: 32px;
    line-height: 38.4px;
    letter-spacing: -0.02em;
  }
  .wing-image {
    width: 290px;
  }
  .wing-info {
    align-items: start;
  }
  .wing-text {
    text-align: start;
  }
  .purpose-info {
    align-items: start;
    gap: 10px;
  }

  .video-title {
    font-size: 30px;
    line-height: 36px;
  }
  .dream-angel-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
  }
  .section-container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .section-container-accordion {
    padding: 0 !important;
  }
  .landing-page-svg {
    padding-top: 130px;
    width: 500px;
  }
  .info-container > h3 {
    white-space: nowrap;
    font-size: 26px;
    line-height: 36px;
  }
  .info-container > span {
    font-size: 16px;
    line-height: 20px;
  }
  .main-info {
    align-items: center;
    width: 100%;
  }
  .carousel-section-title {
    font-size: 26px;
  }
  .window-text-steps {
    font-size: 30px;
  }
  .header-network {
    font-size: 20px;
    line-height: 20px;
  }
  .section-container-live-dream {
    padding: 60px 0;
  }
  .live-dream-text-container {
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    gap: 10px;
  }
  .live-dream-description {
    text-align: center;
  }
  .live-dream-title {
    font-size: 48px;
    line-height: 57.6px;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .gradient-container {
    height: 182px;
  }
  .purpose-btn {
    margin-top: 10px;
  }
  .secure-text {
    font-size: 18px;
  }
  .list-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 360px) {
  .secured-image {
    width: 300px;
    height: auto;
  }
  .dream-angel-container > .gradient-container {
    height: 100%;
  }
  .dream-angel-title {
    font-size: 24px;
    line-height: 40px;
  }
  .list-text {
    font-size: 16px;
    line-height: 26px;
  }
  .secure-text {
    font-size: 14px;
    line-height: 22px;
  }
  .secure-btn {
    width: 245px;
  }
  .info-container > h3 {
    white-space: wrap;
    font-size: 22px;
    line-height: 32px;
  }
  .info-container > span {
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
  }
  .window-text-steps {
    font-size: 26px;
  }
  .number-container {
    width: 280px;
  }
  .white-container-num {
    justify-content: center;
    align-items: center;
  }
}
