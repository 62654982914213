.custom-select-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.custom-select {
  width: 100%;
  height: 40px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 8px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-select-input {
  width: 100%;
  border: none;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #333333;
}
.custom-select-dropdown-container {
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 215px;
  max-width: 360px;
  border-radius: 8px;
  border: 1px solid #b4b5b6;
  box-shadow: 0px 2px 2px 0px #00000026;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: unset !important; 
   padding-right: 4px
}
/* .custom-select-dropdown-container::-webkit-scrollbar {
  display: flex !important;
  border-radius: 8px;
  width:6px !important;
}
.custom-select-dropdown-container::-webkit-scrollbar-thumb {
  background-color: #b4b5b6;
  border-radius: 8px;
  width: 2px !important;
}
.custom-select-dropdown-container::-webkit-scrollbar-track {
  margin: 2px;
} */

.dropdown-bg-transparent {
  position: absolute;
  background: transparent;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
}
.dropdown-bg {
  width: 100%;
  height: auto;
  margin-top: 4px;
}
.dropdown-option {
  width: 100%;
  height: 34px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown-option-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #828282;
}
.dropdown-selected-option-text {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: black;
}
.dropdown-option-selected {
  background-color: #e1e6ef;
}

.transparent-caret:focus {
  caret-color: transparent;
}
.cursor-pointer {
  cursor: pointer;
}
