.about-biography-box {
  width: 860px;
  height: 157px;
  padding: 20px;
  gap: 20px;
  border-radius: 8px;
  flex-direction: column;
  background-color: white;
  margin-top: 35px;
}

.about-title {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.97px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
}

.about-biography-grid {
  width: 820px;
  height: 74px;
  gap: 10px;
}

.about-biography-container {
  width: 197.5px;
  height: 74px;
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.about-biography-image {
  width: 24px;
}

.about-biography-content {
  height: 40px;
}

.about-biography-content p {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #6c6a6a;
}

.biography-data {
  font-family: Space Grotesk;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 22.4px !important;
  letter-spacing: -4% !important;
  text-align: center !important;
  color: #404040 !important;
}

.about-profile-picture {
  width: 860px;
  padding: 20px;
  gap: 20px;
  border-radius: 8px;
  background-color: white;
  flex-direction: column;
}

.about-description {
  width: 860px;
  padding: 20px;
  gap: 10px;
  border-radius: 8px;
  background-color: white;
  flex-direction: column;
}

.about-missing-profile-image {
  position: absolute;
  text-align: center;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-add-profile-image {
  right: 10px;
  padding: 0;
  width: 32px;
  bottom: 10px;
  position: absolute;
}

.delete-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 90px;
  margin-bottom: 100px;
  left: 0;
  right: 8px;
  text-align: center;
  align-self: center;
}
.delete-icon-dream-photo {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.delete-icon-edit-profile {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.carousel-about-images {
  user-select: none;
}
.about-already-fulfilled-container {
  width: 860px;
}

.about-profile-picture .bVoXXg {
  position: relative;
  display: inline-block;
  height: 200px;
}

.about-already-fulfilled-box {
  width: 100%;
  padding: 20px;
  gap: 20px;
  border-radius: 8px;
  flex-direction: column;
  background-color: white;
}

.about-fullfilled-img-name {
  height: 40px;
  display: flex;
}

.about-fulfilled-name-box {
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.42px;
  letter-spacing: -0.02em;
  color: #252525;
  height: 20px;
  padding: 10px;
}

.about-description-profile {
  font-family: Space Grotesk;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #6c6a6a;
}

.about-fulfilled-date-button {
  display: flex;
  padding: 0 6px;
  gap: 4px;
  background: linear-gradient(
    114.93deg,
    rgba(132, 250, 213, 0.2) 1.02%,
    rgba(235, 191, 255, 0.2) 44.33%,
    rgba(246, 236, 133, 0.2) 76.07%
  );
  height: 100%;
  width: 100%;
  border-radius: 8px;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: #333333;
  align-items: center;
}

.region-dropdown {
  background-color: #f8f9fa;
  height: 35px;
}

.enabled-region-dropdown {
  background-color: white;
  height: 35px;
}

.about-custom-gradient {
  background: linear-gradient(white, white),
    linear-gradient(297.06deg, #f8ed84 23.88%, #f5e0ff 66.2%, #84fad5 109.31%);
  border-radius: 8px;
  border: double 1px transparent !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 30px;
}

.about-fulfilled-dream-description {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #404040;
}

.about-comments-line-bar {
  border-top: 1px solid #f1f1f1;
  padding-top: 20px;
}

@media (max-width: 862px) {
  .about-biography-box {
    width: 96vw;
  }
  .about-profile-picture {
    width: 96vw;
  }
  .about-description {
    width: 96vw;
  }
  .dream-slider-query {
    width: 96vw;
  }
  .about-already-fulfilled-container {
    width: 96vw;
  }
  .about-biography-grid {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .about-biography-box {
    height: 238px;
  }
  .about-biography-grid {
    grid-template-columns: auto auto;
    display: grid !important;
    justify-items: center;
  }
  .about-biography-container {
    width: 40vw;
  }
}

@media (max-width: 550px) {
  .public-profile-button-container {
    margin: auto;
    width: fit-content;
  }
  .about-custom-gradient {
    height: 38px;
    margin-bottom: 10px;
  }
  .about-fulfilled-date-button {
    padding: 5px 6px;
    justify-content: center;
  }
}

.tooltipes {
  position: relative;
  display: inline-block;
}

.tooltipes .tooltiptextes {
  visibility: hidden;
  width: 320px !important;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -148px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipes .tooltiptextes-mobile {
  visibility: visible;
  width: 320px !important;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -148px;
  opacity: 1;
  transition: opacity 0.3s;
}

.tooltip-remove {
  width: 320px !important;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -148px;
  transition: opacity 0.3s;
  visibility: hidden !important;
  opacity: 0 !important;
}

.tooltiptextes span,
.tooltiptextes-mobile span {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  text-align: left;
  color: #ffffff;
}

.tooltipes .tooltiptextes::after,
.tooltipes .tooltiptextes-mobile::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltipes:hover .tooltiptextes {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1168px) {
  .tooltipes .tooltiptextes,
  .tooltipes .tooltiptextes-mobile {
    margin-left: -296px;
  }

  .tooltipes .tooltiptextes::after,
  .tooltipes .tooltiptextes-mobile::after {
    left: 96%;
  }
}
