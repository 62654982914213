.custom-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-description {
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black;
}

.custom-input {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  padding: 0 9px;
  gap: 4px;
}
.custom-input-field {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #333333;
}
.dollar-icon {
  width: 18px;
  height: 18px;
}
.custom-input-error {
  border: 1px solid #e44747;
}

.custom-input:focus-within {
  border: 2px solid #ebbfff;
}

.error-message {
  font-family: Space Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #333333;
}

.error-info-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  height: 24px;
}
.error-info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}
